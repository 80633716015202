import * as React from 'react';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { close_snack_bar } from './snackbar_action';
import { useDispatch } from 'react-redux';
export default function CustomizedSnackbars({ message, severity, openSnackbar }) {
    const [open, setOpen] = React.useState(openSnackbar);
    const dispatch = useDispatch();


    const handleClick = () => {
        setOpen(true);
    };
    const handleClose = (event, reason) => {
        dispatch(close_snack_bar());
        setOpen(false)
    };

    return (
        <div>
            <Snackbar open={openSnackbar} autoHideDuration={3000} onClose={handleClose}>
                <Alert
                    onClose={handleClose}
                    severity={severity}
                    variant="filled"
                    sx={{ width: '100%' }}
                >
                    {message}
                </Alert>
            </Snackbar>
        </div>
    );
}
