import React, { Component } from "react";
// import firebase from "firebase";
// import { firebase_config } from "../../constants/ActionTypes";
import { useParams, useLocation } from "react-router-dom";
function withParams(Component) {
  return props => <Component {...props} params={useParams()} location={useLocation()} />;
}
class LoginController extends Component {

  constructor(props) {
    super(props);
    this.state = {
      redirect: false,
      page: ""
    };
  }

  componentDidMount() {
    if (localStorage.getItem("token") !== null) {
      if (this.props.location) {
        const { search } = this.props.location;
        const params = new URLSearchParams(search);
        const encodedData = params.get('data');
        if (encodedData) {
          const { token, object_id } = JSON.parse(decodeURIComponent(encodedData));
          this.setState({
            object_id: object_id
          })
          this.props.fetchToken(localStorage.getItem("token"), object_id)
        }
      }
      else {
        this.props.fetchToken(localStorage.getItem("token"), this.props.params.object_id)
      }
    }
  }

  render() {
    return (
      <div />
    );
  }
}

export default withParams(LoginController)
