import React, { Component } from "react";
import { connect } from "react-redux";
import Profile from "../Components/Login";
// import { close_snack_bar } from "../../common/snackbar/action";
import {
  login_by_email,
  setOtp,
  verify_otp_email,
  create_login,
  getAccessByUser,
  getOwnedOrg,
  login_by_mobile,
  verify_otp_mobile,
  checkToken,
  checkObjectId,
  verify_otp_email_new_user
} from "../Action";
export class ProfileContainer extends Component {
  render() {
    return (
      <Profile {...this.props} />
    );
  }
};
export const mapStateToProps = store => {
  return {
    login: store.login,
    loader: store.loader,
    // snackbar: store.snackbar,
  };
};
export const mapDispatchToProps = dispatch => {
  return {
    // close_snack_bar: () => {
    //   dispatch(close_snack_bar());
    // },
    checkToken: (token) => {
      dispatch(checkToken(token));
    },
    checkObjectId: (token, object_id) => {
      dispatch(checkObjectId(token, object_id));
    },

    // getUser: (email, password, token) => {
    //   dispatch(getUser(email, password, token));
    // },
    login_by_email: (email, login_id) => {
      dispatch(login_by_email(email, login_id));
    },
    login_by_mobile: (mobile, login_id) => {
      dispatch(login_by_mobile(mobile, login_id));
    },
    setOtp: (otp) => {
      dispatch(setOtp(otp));
    },
    verify_otp_email: (email, otp, login_id,) => {
      dispatch(verify_otp_email(email, otp, login_id));
    },
    verify_otp_email_new_user: (email, otp, login_id, mobile, name) => {
      dispatch(verify_otp_email_new_user(email, otp, login_id, mobile, name));
    },
    verify_otp_mobile: (mobile, otp, login_id) => {
      dispatch(verify_otp_mobile(mobile, otp, login_id));
    },
    create_login: () => {
      dispatch(create_login());
    },
    getAccessByUser: (token, loginObj_id) => {
      dispatch(getAccessByUser(token, loginObj_id));
    },
    getOwnedOrg: (token) => {
      dispatch(getOwnedOrg(token));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ProfileContainer);