import React, { Component } from "react";
import { connect } from "react-redux";
import LoginController from "../components/controller";

import {
  fetchToken
} from "../../pages/Admin/Action";


export class Controller_con extends Component {

  render() {
    return (
      <LoginController {...this.props} />
    );
  }
};

export const mapStateToProps = store => {
  return {
    login: store.login,
  };
};

export const mapDispatchToProps = dispatch => {
  return {
    fetchToken: (token,object_id) => {
      dispatch(fetchToken(token,object_id));
    },
    
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Controller_con);