import React from 'react';
import { Chip, Grid, Typography } from '@mui/material';
import Smartphone from '../image/smartphone.png';
import Computer from '../image/computer.png';
import moment from 'moment';
import AdjustIcon from '@mui/icons-material/Adjust';

const Device = ({ device_data }) => {
    return (
        <Grid p={2}>
            <center>
                <Typography className='text' variant="h5">Devices</Typography>
                <Typography className='text' fontSize={'12px'} m={1}>
                    Your devices where you're signed in, showing multiple sessions on Windows computers and Android phones.
                </Typography>
            </center>

            <Grid container mt={5}>
                <Grid item xs={1} />
                <Grid item xs={10}>
                    <Grid container spacing={2}>
                        {Array.isArray(device_data) && device_data.map((device, index) => (
                            <Grid
                                item
                                key={index}
                                xs={12}
                                sm={6}
                                md={4}
                            >
                                <Grid container spacing={1} style={{ borderRadius: '10px', border: '1px solid #ebecf0' }}>
                                    <Grid item >
                                        <img
                                            src={device.device_type === 'desktop' ? Computer : Smartphone}
                                            alt={device.device_type}
                                            style={{ width: '50px', height: '50px', padding: '10px' }}
                                        />
                                        <center>
                                            <Chip
                                                style={{
                                                    backgroundColor: device.status === 'Active' ? 'rgba(144, 238, 144, 0.5)' : 'rgba(255, 102, 102, 0.5)',
                                                    padding: '0px', marginBottom: 5
                                                }}
                                                label={<Typography className='text' fontSize={'10px'}>{device.status}</Typography>}
                                                icon={<AdjustIcon fontSize='small' style={{ color: device.status === 'Active' ? 'green' : 'red' }} />}
                                                size="small"
                                            />
                                        </center>
                                    </Grid>
                                    <Grid item xs>
                                        <Grid mr={1} style={{ float: 'right' }}>
                                            <Typography className='text' fontSize={'10px'}>Created on : {moment(device.createdAt).format('L')}</Typography>
                                        </Grid>
                                        <br />
                                        <Typography className='text'>{device.device_type === 'desktop' ? 'Computer' : 'Smartphone'}</Typography>
                                        <Typography className='text' fontSize={'12px'} color="textSecondary">
                                            {moment(device.updatedAt).calendar()}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        ))}
                    </Grid>
                </Grid>
                <Grid item xs={1} />
            </Grid>

        </Grid>
    )
};

export default Device;
