import React, { Component } from "react";
import {
  Grid,
  Card,
  Typography,
  CardContent,
  Button,
  TextField,
  Avatar,
  Tooltip,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Modal,
  Backdrop,
  Fade,
} from "@mui/material";
import logo from "../Components/image/logoDark.png";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import DriveFileRenameOutlineTwoToneIcon from "@mui/icons-material/DriveFileRenameOutlineTwoTone";
import org from "../Components/image/icons8-organization-100.png";
import AddIcon from "@mui/icons-material/Add";
import createOrg from "./image/create.avif";
import create2 from "./image/create2.jpg";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import { Link } from "react-router-dom";

import ShowProfile from "./ShowOrganization";
import pic from "../Components/image/profile-user.png";

export default class AddOrganization extends Component {
  constructor(props) {
    super(props);
    this.state = {
      OrganizationDialog: false,
      openModal: false,
      name: "",
      email: "",
      phone: "",
      address: "",
      organizations: [
        { name: "Organization 1", avatar: null },
        { name: "Organization 2", avatar: null },
        { name: "Organization 3", avatar: null },
        { name: "Organization 4", avatar: null },
        { name: "Organization 5", avatar: null },
       
      ],
    };
  }

  handleOpenModal = () => {
    this.setState({ openModal: true });
  };

  handleCloseModal = () => {
    this.setState({ openModal: false });
  };

  handleOrganizationNameChange = (e) => {
    this.setState({ organizationName: e.target.value });
  };

  handleInputChange = (e, field) => {
    this.setState({ [field]: e.target.value });
  };

  handleAddOrganization = () => {
    console.log(
      "Adding organization:",
      this.state.organizationName,
      this.state.email,
      this.state.phoneNumber,
      this.state.address
    );

    this.setState({ openModal: false });

    this.setState({ organizationName: "", email: "", phone: "", address: "" });
  };

  render() {
    const {
      openModal,
      organizationName,
      email,
      phone,
      address,
      organizations,
    } = this.state;

    return (
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        style={{
          height: "100vh",
          backgroundColor: "#e6e6e9",
          overflow: "hidden",
          background:
            "linear-gradient(to right top, #c6ece5, #c7eee5, #c9f0e4, #cbf2e3, #cdf4e2, #cbf5e0, #caf5de, #c9f6dc, #c3f6d8, #bef6d5, #b8f6d1, #b3f6cd)",
        }}
      >
        <Grid
          container
          justify="center"
          style={{ width: "60%", marginBottom: "20px" }}
        >
          <Grid
            item
            xs={12}
            className="parentOne"
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Card
              style={{
                display: "flex",

                borderRadius: "5px",
                justifyContent: "center",
                padding: "20px",
                alignItems: "center",
                transition: "transform 0.3s",
                "&:hover": { transform: "scale(1.05)" },
                width: "100%",
                height: "100%",
                marginTop: "10px",
                marginBottom: "20px",
              }}
            >
              <Grid item xs={7.5} style={{ width: "100%" }}>
                <Grid
                  container
                  direction="column"
                  alignItems="center"
                  justifyContent="center"
                  style={{ width: "100%" }}
                >
                  <Grid item xs={12}>
                    <Link to="/Organization">
                      <IconButton>
                        <ArrowBackRoundedIcon
                          cursor="pointer"
                          sx={{ marginLeft: "-300px", alignItems: "left" }}
                        />
                      </IconButton>
                    </Link>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginBottom: "20px",
                      marginTop: "-30px",
                    }}
                  >
                    <img
                      src={logo}
                      style={{
                        width: "30%",
                        height: "30%",
                        objectFit: "cover",
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    style={{
                      marginBottom: "5px",
                      width: "50%",
                      display: "flex",
                      alignItems: "center",
                      marginBottom: "30px",
                    }}
                  >
                    <Typography
                      variant="h5"
                      align="center"
                      style={{
                        fontWeight: "600",

                        borderRadius: "10px",
                        width: "100%",
                        padding: "2px",
                        fontFamily: "sans-serif",
                        color: "#416165",
                        fontSize: "1.2rem",
                        flexGlow: "1",
                      }}
                    >
                      Existing Organizations
                    </Typography>
                  </Grid>
                  <Grid
                    container
                    justifyContent="center"
                    alignItems="center"
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginLeft: "50px",
                      maxHeight: "200px",
                      overflowY: "auto",
                    }}
                  >
                    <Grid item xs={12}>
                      {organizations.map((organization, index) => (
                        <ShowProfile key={index} organization={organization} />
                      ))}
                    </Grid>
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    sx={{
                      width: "50%",
                      marginBottom: "40px",
                      height: "50%",
                      marginTop: "10px",
                    }}
                  >
                    {/*
                         <Button
                        variant="outlined"
                        onClick={this.handleOpenModal}
                        fullWidth
                        startIcon={<AddIcon />}
                        sx={{
                          backgroundColor: "#425a4f",
                          color: "white",
                          fontSize: "0.8rem",
                          borderRadius: "10px",
                          "&:hover": {
                            color: "#766153",
                            backgroundColor: "white",

                            border: "1px solid #626868",
                          },
                        }}
                      >
                       Add Organization
                      </Button>
                        */}

                    <Modal
                      aria-labelledby="transition-modal-title"
                      aria-describedby="transition-modal-description"
                      open={openModal}
                      onClose={this.handleCloseModal}
                    >
                      <Grid
                        sm={12}
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "100vh",
                        }}
                      >
                        <Card
                          style={{
                            padding: "20px",
                            width: "300px",
                            backgroundColor: "white",
                            boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.1)",
                          }}
                        >
                          <Typography
                            id="transition-modal-title"
                            variant="h6"
                            align="center"
                            gutterBottom
                            sx={{
                              color: "#6c756b",
                              fontSize: "1rem",
                              fontWeight: "400",
                            }}
                          >
                            Are you sure you want to add this organization
                          </Typography>

                          <Grid container spacing={2} justifyContent="center">
                            <Grid item>
                              <Button
                                variant="contained"
                                onClick={this.handleAddOrganization}
                                sx={{
                                  backgroundColor: "#cad5ca",
                                  color: "#3a3e3b",
                                  "&:hover": {
                                    color: "white",
                                    backgroundColor: "#3a3e3b",

                                    border: "1px solid #626868",
                                  },
                                }}
                              >
                                Add
                              </Button>
                            </Grid>
                            <Grid item>
                              <Button
                                variant="outlined"
                                onClick={this.handleCloseModal}
                                sx={{
                                  color: "#425a4f",
                                  borderColor: "#425a4f",
                                  "&:hover": {
                                    color: "white",
                                    backgroundColor: "#3a3e3b",

                                    border: "1px solid #626868",
                                  },
                                }}
                              >
                                Cancel
                              </Button>
                            </Grid>
                          </Grid>
                        </Card>
                      </Grid>
                    </Modal>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                item
                xs={4.5}
                className="frame"
                style={{
                  borderRadius: "5px",
                  width: "100%",
                  marginBottom: "10px",
                }}
              >
                <img
                  src={create2}
                  style={{ width: "100%", height: "100%", objectFit: "cover" }}
                />
              </Grid>
            </Card>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}
