import React, { Component } from "react";
import { BrowserRouter as Router, Route, Routes, } from "react-router-dom";
import Controller from "../containers/controller_cont";

import Profile from "../../pages/Admin/Containers/ProfileCont"
import Login from "../../pages/Admin/Containers/LoginCont"
import GoogleCallBack from "../../pages/Admin/Components/GoogleWrapper"
import Organization from "../../pages/Admin/Containers/OrganizationCont";
import AddOrganization from "../../pages/Admin/Components/AddOrganization"

class Routers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: null,
    };
    this.setUser = this.setUser.bind(this);
  }

  setUser(user) {
    this.setState({ user });
  }
  render() {
    let body;
    if (!(this.props.login.token) || localStorage.getItem('token') === null) {
      
      body = (
        <div>

          <main style={{ flexGrow: 1 }}>
            <Controller />
            <Routes>
              <Route exact path="/Login" element={<Login />} />
              <Route exact path="/" element={<Login />} />
              <Route exact path="/:object_id" element={<Login />} />
              <Route exact path="/auth/google/callback" element={<GoogleCallBack setUser={this.setUser} />} />
            </Routes>
          </main>
        </div>
      );
    }
    // else if(this.props.login.profile_view || localStorage.getItem('profile') === 'true'){
    //   body = (
    //     <div>

    //       <main style={{ flexGrow: 1 }}>
    //         <Controller />
    //         <Routes>
    //           {/* <Route exact path="/" element={<Profile props={this.props}/>} /> */}
    //           <Route exact path="/Profile" element={<Profile props={this.props}/>} />
    //         </Routes>
    //       </main>
    //     </div>
    //   );
    // }
    else {
      
      body = (
        <div>
          <main style={{ flexGrow: 1 }}>
            <Controller />
            <Routes>
              <Route exact path="/" element={<Profile props={this.props}/>} />
              <Route exact path="/:object_id" element={<Organization />} />
              <Route exact path="/Organization" element={<Organization />} />
              <Route exact path="/AddOrganization" element={<AddOrganization />} />
            </Routes>
          </main>
        </div>
      );
    }



    return <Router>{body}</Router>;
  }
}

export default Routers;
