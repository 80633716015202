import { Chip, Grid, Typography } from '@mui/material';
import React from 'react';
import Smartphone from '../image/software.png';
import moment from 'moment';

const Organisation = ({ org_data }) => {
  return (
    <Grid pl={5} pr={5} pt={5}>
      <Grid
        style={{
          backgroundColor: 'rgba(209, 116, 7, 0.1)',
          borderTopLeftRadius: '50px',
          borderTopRightRadius: '50px',
        }}
      >
        <Grid p={5}>
          <center>
            <Typography className='text' variant="h5">
              Organisation
            </Typography>
            <Typography className='text' fontSize={'12px'} m={1}>
              Manage organizations with detailed last login records and access type information for each.
            </Typography>
          </center>

          <Grid container mt={5}>
            <Grid item xs={1} />
            <Grid item xs={10}>
              <Grid container spacing={2}>
                {Array.isArray(org_data) && org_data.map((org, index) => (
                  <Grid
                    item
                    key={index}
                    xs={12}
                    sm={12}
                    md={6}
                  >
                    <Grid container direction="column" justifyContent="center" mt={1} p={1} spacing={1} style={{ borderRadius: '10px', backgroundColor: '#FFFFFF' }}>
                      <Typography className='text' variant="h6" color='GrayText' component="div" pl={2}>
                        {org.product?.product}
                      </Typography>
                      <Grid style={{ display: 'flex' }}>
                        <img src={Smartphone} alt={org.type} style={{ width: '40px', height: '40px', padding: '10px' }} />
                        <Grid>
                          <Typography className='text' variant="body1" fontWeight={600}>
                            {org.org_id?.org_name}
                          </Typography>
                          <Typography className='text' fontSize={'10px'} color="textSecondary">
                            {org.org_id?.org_address}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid style={{ marginTop: 8, display: 'flex', justifyContent: 'flex-end' }}>
                        <Chip
                          label={org?.type}
                          className='text'
                          style={{ fontWeight: 600, boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.1)', color: 'rgb(0, 0, 255)', backgroundColor: 'rgba(173, 216, 230, 0.5)' }}
                        />
                      </Grid>
                      <Grid mt={2}>
                        <Typography className='text' fontSize={'12px'} color="textSecondary">
                          Created on - {moment(org.org_id?.createdAt).calendar()}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            </Grid>
            <Grid item xs={1} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
};

export default Organisation;
