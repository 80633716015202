import { Grid, List, ListItem, ListItemText, Typography } from '@mui/material';
import React from 'react';

const Sidebar = ({ items, selectedTab, setSelectedTab }) => (
  <Grid>
    <List>
      {items.map((item, index) => (
        <ListItem
          component='a'
          key={index}
          href={`#${item.id}`}
          onClick={() => setSelectedTab(item.text)}
          sx={{
            backgroundColor: selectedTab === item.text ? 'rgba(209, 116, 7, 0.1)' : 'transparent',
            color: selectedTab === item.text ? '#d17407' : 'inherit',
            borderTopRightRadius: '10px',
            borderBottomRightRadius: '50px',
            cursor: 'pointer',
            textDecoration: 'none',
          }}
          aria-current={selectedTab === item.text ? 'true' : undefined}
        >
          <Grid sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            {React.cloneElement(item.icon, {
              sx: { color: selectedTab === item.text ? '#d17407' : 'inherit' },
            })}
            <ListItemText primary={<Typography className='text' variant='subtitle2'>{item.text}</Typography>} />
          </Grid>
        </ListItem>
      ))}
    </List>
  </Grid>
);

export default Sidebar;
