import React, { Component } from "react";
import { connect } from "react-redux";
import Profile from "../Components/Profile/Profile";
import { getUserData, getDeviceData, getOrgdata, logOutByToken } from "../Action";
export class ProfileContainer extends Component {
    render() {
        return (
            <Profile {...this.props} />
        );
    }
};
export const mapStateToProps = store => {
    return {
        login: store.login,
        loader: store.loader,
    };
};
export const mapDispatchToProps = dispatch => {
    return {
        getUserData: (token) => {
            dispatch(getUserData(token));
        },
        getDeviceData: (token) => {
            dispatch(getDeviceData(token));
        },
        getOrgdata: (token) => {
            dispatch(getOrgdata(token));
        },
        logOutByToken: (token) => {
            dispatch(logOutByToken(token));
        },
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(ProfileContainer);