import {
  // GETUSER,
  OTP,
  EMAIL_OTP,
  MOBILE_OTP,
  TOKEN,
  ORG,
  OWN_ORG,
  LOGIN,
  LOGOUT,
  USER_DETAILS,
  PROFILE_VIEW,
  USER_DATA,
  DEVICE_DATA,
  ORG_DATA,
  EMAIL_VERIFICATION,
  MOBILE_VERIFICATION,
  RESET_OTP,
  CLEAR
} from "./Constant";
const initial_state = {
  user_profile: {},
  getUserToken: {},
  otp: false,
  token: '',
  org: [],
  own_org: [],
  user_id: "",
  org_id: '',
  isHome: true,
  orgHome: false,
  mobile: '',
  name: '',
  email: '',
  profile_pic: '',
  user_details: [],
  profile_view: false,
  user_data: {},
  device_data: [],
  device_length: 0,
  org_data: [],
  org_length: 0,
  verification: false

};
export default function reducer(state = initial_state, action) {
  switch (action.type) {
    case LOGIN:
      return state = {
        ...state,
        isHome: false,
        user_id: action.payload._id,
        mobile: action.payload.mobile,
        name: action.payload.name,
        email: action.payload.email_id,
        profile_pic: action.payload.profile_pic,
        token: action.payload.token,
        orgHome: true
      };

    case LOGOUT:
      return state = {
        ...state,
        isHome: true,
        user_id: "",
        mobile: "",
        name: "",
        token: "",
        profile_pic: "",

        orgHome: false
      };

    case USER_DETAILS:

      return state = {
        ...state,
        user_details: action.payload,

      };

    case OTP:
      return state = {
        ...state,
        otp: action.payload,
      };

    case TOKEN:
      return state = {
        ...state,
        token: action.payload,
      };

    case ORG:
      return state = {
        ...state,
        org: action.payload,
      };

    case OWN_ORG:
      return state = {
        ...state,
        own_org: action.payload,
      };

    case PROFILE_VIEW:
      return state = {
        ...state,
        profile_view: action.payload.profile,
        token: action.payload.token
      };

    case USER_DATA:
      return state = {
        ...state,
        user_data: action.payload
      };

    case DEVICE_DATA:
      return state = {
        ...state,
        device_data: action.payload
      };

    case ORG_DATA:
      return state = {
        ...state,
        org_data: action.payload
      };

    case EMAIL_OTP:
      return state = {
        ...state,
        email_otp: action.payload,
      };
    case EMAIL_VERIFICATION:
      return state = {
        ...state,
        email_verification: action.payload
      };
    case MOBILE_OTP:
      return state = {
        ...state,
        mobile_otp: action.payload,
      };
    case MOBILE_VERIFICATION:
      return state = {
        ...state,
        mobile_verification: action.payload
      };
    case OTP:
      return state = {
        ...state,
        otp: action.payload,
      };

    case RESET_OTP:
      return state = {
        ...state,
        email_verification: false,
        mobile_verification: false,
        email_otp: false,
        mobile_otp: false,
      };

    default:
      return state;
  }
}
