import React, { Component } from "react";
import { connect } from "react-redux";
import Profile from "../Components/Organization";
// import { close_snack_bar } from "../../common/snackbar/action";
import {
  getUser,
  login_by_email,
  setOtp,
  verify_otp_email,
  create_login,
  getAccessByUser,
  getOwnedOrg,
  createNewAccess,
  createOrg,
  selectOrg,
  logOutByToken
} from "../Action";
export class ProfileContainer extends Component {
  render() {
    return (
      <Profile {...this.props} />
    );
  }
};
export const mapStateToProps = store => {
  return {
    login: store.login,
    loader: store.loader,
    // snackbar: store.snackbar,
  };
};
export const mapDispatchToProps = dispatch => {
  return {
    // close_snack_bar: () => {
    //   dispatch(close_snack_bar());
    // },
   
    // getUser: (email, password, token) => {
    //   dispatch(getUser(email, password, token));
    // },
    login_by_email: (email, login_id) => {
      dispatch(login_by_email(email, login_id));
    },
    setOtp: (otp) => {
      dispatch(setOtp(otp));
    },
    verify_otp_email: (email, otp, login_id) => {
      dispatch(verify_otp_email(email, otp, login_id));
    },
    create_login: () => {
      dispatch(create_login());
    },
    logOutByToken: (token) => {
      dispatch(logOutByToken(token));
    },
    getAccessByUser: (token,object_id) => {
      dispatch(getAccessByUser(token,object_id));
    },
    getOwnedOrg: (token) => {
      dispatch(getOwnedOrg(token));
    },
    createNewAccess: (token, org_id,object_id) => {
      dispatch(createNewAccess(token, org_id,object_id) );
    },
    createOrg: (token, org_name, org_email, org_phone, org_address,object_id) => {
      dispatch(createOrg(token, org_name, org_email, org_phone, org_address,object_id));
    },
    selectOrg: (token, org_id, object_id) => {
      dispatch(selectOrg(token, org_id, object_id));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ProfileContainer);