import {
  SET_SNACK_BAR,
  CLOSE_SNACK_BAR
} from "./snackbar_const";
const initial_state = {
  response_received: false,
  message: "nothing",
  sevirity:'success'
};
export default function reducer(state = initial_state, action) {
  
  switch (action.type) {
    case SET_SNACK_BAR:
      
      return (state = { ...state, response_received: true, message: action.payload.message, sevirity: action.payload.sevirity });
    case CLOSE_SNACK_BAR:
      return (state = { ...state, response_received: false, message: "", sevirity: "" });
    default:
      return state;
  }
}
