import React, { Component } from "react";
import { connect } from "react-redux";
import Routes from "../components/router";
import { getDeviceData, getOrgdata, getUserData, sendOtpToEmail } from "../../pages/Admin/Action";
export class Routes_con extends Component {
  render() {
    return (
      <Routes {...this.props} />
    );
  }
};

export const mapStateToProps = store => { 
  return {
   login: store.login,
  };
};

export const mapDispatchToProps = dispatch => {
    return {
      getUserData: (token) => {
        dispatch(getUserData(token));
      },
      getOrgdata: (token) => {
        dispatch(getOrgdata(token));
      },
      getDeviceData: (token) => {
        dispatch(getDeviceData(token));
      },
      sendOtpToEmail: (token, snackbarProps) => {
        dispatch(sendOtpToEmail(token, snackbarProps));
      },
    };
  };

export default connect(mapStateToProps, mapDispatchToProps)(Routes_con );