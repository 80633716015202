import { Avatar, Button, Divider, Grid, Typography } from '@mui/material';
import React from 'react';
import Logo from '../image/sticker.png';
import Privacy from '../image/Personal settings.gif';
import Device from '../image/Devices.gif';
import Org from '../image/Online world.gif';

const Home = ({ user_data, scrollToSection }) => (
    <Grid p={1.5} mt={1}>
        <Grid display="flex" alignItems='baseline' justifyContent='space-between'>
            <Typography className='text' variant="h5" textAlign='left' ml={1} color='gray'>
                Welcome, <br />{user_data?.name}
            </Typography>
            <Avatar sx={{ width: 90, height: 90, border: '0.2px solid gray', mr: 6.5 }} src={user_data?.profile_pic} alt="User Logo"  />
        </Grid>
        <Divider />
        <center>
            <Typography className='text' fontSize='12px' m={1}>
                Manage your info, privacy, and security to make Technoboot work better for you.
            </Typography>
        </center>
        <Grid container pl={5} pt={3} pr={5} gap={2}>
            <Grid item xs={12} sm={12} md={5.8} sx={{ borderRadius: '10px', border: '1px solid #ebecf0' }}>
                <Grid display='flex' justifyContent='space-between' gap={10} mb={1} pl={3} pr={3} pt={3}>
                    <Grid>
                        <Typography className='text' variant='body2' fontWeight={600}>Privacy & Personalisation</Typography>
                        <Typography className='text' fontSize='12px'>View your data in your Employee Account and choose what information is recorded to personalize your work experience.</Typography>
                    </Grid>
                    <Avatar sx={{ height: 150, width: 120 }} src={Privacy} alt="Privacy Settings" />
                </Grid>
                <Divider />
                <Button
                    className='text'
                    fullWidth
                    sx={{ textTransform: 'capitalize', color: '#d17407', '&:hover': { backgroundColor: 'rgba(209, 116, 7, 0.1)' } }}
                    onClick={() => scrollToSection('Personal info')} // Call scrollToSection with the corresponding ID
                >
                    Personal Info
                </Button>
            </Grid>
            <Grid item xs={12} sm={12} md={5.8} sx={{ borderRadius: '10px', border: '1px solid #ebecf0' }}>
                <Grid display='flex' justifyContent='space-between' gap={10} mb={1} pl={3} pr={3} pt={3}>
                    <Grid>
                        <Typography className='text' variant='body2' fontWeight={600}>Devices</Typography>
                        <Typography className='text' fontSize='12px'>Analyze the devices linked to your Employee Account to customize and optimize your work experience.</Typography>
                    </Grid>
                    <Avatar sx={{ height: 150, width: 120 }} src={Device} alt="Devices" />
                </Grid>
                <Divider />
                <Button
                    className='text'
                    fullWidth
                    sx={{ textTransform: 'capitalize', color: '#d17407', '&:hover': { backgroundColor: 'rgba(209, 116, 7, 0.1)' } }}
                    onClick={() => scrollToSection('Devices')} // Call scrollToSection with the corresponding ID
                >
                    Devices
                </Button>
            </Grid>
            <Grid item xs={12} sx={{ borderRadius: '10px', border: '1px solid #ebecf0' }}>
                <Grid display='flex' justifyContent='space-between' gap={10} mb={1} pl={3} pr={3} pt={3}>
                    <Grid>
                        <Typography className='text' variant='body2' fontWeight={600}>Organisation</Typography>
                        <Typography className='text' fontSize='12px'>Review the data in your Employee Account to analyze the organizations you've logged into. This information helps you understand your engagement with different organizations and allows for a more personalized work experience based on your login history.</Typography>
                    </Grid>
                    <Avatar sx={{ height: 150, width: 120 }} src={Org} alt="Organisation" />
                </Grid>
                <Divider />
                <Button
                    className='text'
                    fullWidth
                    sx={{ textTransform: 'capitalize', color: '#d17407', '&:hover': { backgroundColor: 'rgba(209, 116, 7, 0.1)' } }}
                    onClick={() => scrollToSection('Organisation')} // Call scrollToSection with the corresponding ID
                >
                    Organisation
                </Button>
            </Grid>
        </Grid>
    </Grid>
);

export default Home;
