import React, { Component } from "react";
import { connect } from "react-redux";
import Basic from "../Components/Profile/Basic";
import { sendOtpToEmail, sendOtpToMobile, updateEmail, updateMobile, updateName, updateProfile, verifyEmail, verifyMobile, resetOtpVerification } from "../Action";
export class ProfileContainer extends Component {
    render() {
        return (
            <Basic {...this.props} />
        );
    }
};
export const mapStateToProps = store => {
    return {
        login: store.login,
        loader: store.loader,
        snackbar: store.snackbar
    };
};
export const mapDispatchToProps = dispatch => {
    return {
        sendOtpToEmail: (token, snackbarProps) => {
            dispatch(sendOtpToEmail(token, snackbarProps));
        },
        sendOtpToMobile: (token, mobile, snackbarProps) => {
            dispatch(sendOtpToMobile(token, mobile, snackbarProps));
        },
        verifyEmail: (token, otp) => {
            dispatch(verifyEmail(token, otp));
        },
        verifyMobile: (token, mobile, otp) => {
            dispatch(verifyMobile(token, mobile, otp));
        },
        resetOtpVerification: () => {
            dispatch(resetOtpVerification());
        },
        updateEmail: (token, email_id) => {
            dispatch(updateEmail(token, email_id));
        },
        updateMobile: (token, mobile) => {
            dispatch(updateMobile(token, mobile));
        },
        updateName: (token, name) => {
            dispatch(updateName(token, name));
        },
        updateProfile: (token, profile_pic) => {
            dispatch(updateProfile(token, profile_pic));
        },
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(ProfileContainer);