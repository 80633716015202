import {
    SET_SNACK_BAR,
    CLOSE_SNACK_BAR
} from "../snackbar/snackbar_const";
//   FUNCTION TO CLOSE SNACKBAR
export function close_snack_bar() {
    
    return {
        type: CLOSE_SNACK_BAR
    };
}
//   FUNCTION FOR SHOWING SNACK BAR
export function set_snack_bar(payload, message, sevirity) {
    var data = [];
    data.status = payload;
    data.message = message;
    data.sevirity = sevirity;
    
    return {
        type: SET_SNACK_BAR,
        payload: data
    };
}