import React, { Component } from "react";
import {
  Grid,
  Card,
  Typography,
  CardContent,
  Button,
  TextField,
  Avatar,
  Tooltip,
  Modal,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Toolbar,
  AppBar,
  Box,
  closeModal
} from "@mui/material";
import logo from "../Components/image/logoDark.png";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import DriveFileRenameOutlineTwoToneIcon from "@mui/icons-material/DriveFileRenameOutlineTwoTone";
import org from "../Components/image/icons8-organization-100.png";
import AddIcon from "@mui/icons-material/Add";
import AddOrganization from "./AddOrganization";
import organization from "./image/organization.jpg";
import { Link } from "react-router-dom";
import Login from "../Components/Login";
import { useParams, useLocation } from "react-router-dom";
import LoaderCon from '../../../loader/containers/loader_cont'
function withParams(Component) {
  return props => <Component {...props} params={useParams()} location={useLocation()} />;
}

class Organization extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ProfileDialog: false,
      OrganizationDialog: false,
      openModal: false,
      openModal1: false,
      name: "",
      email: "",
      phone: "",
      address: "",
      modalIsOpen: false,
      object_id: ''
    };
  }

  handleOpenModal = () => {
    this.setState({ openModal: true });
  };
  handleOpenModal1 = () => {
    this.setState({ openModal1: true });
  };

  handleCloseModal = () => {
    this.setState({ openModal: false });
  };
  handleCloseModal1 = () => {
    this.setState({ openModal1: false });
  };

  handleOrganizationNameChange = (e) => {
    this.setState({ organizationName: e.target.value });
  };

  handleInputChange = (e, field) => {
    this.setState({ [field]: e.target.value });
  };

  handleAddOrganization = () => {
    console.log(
      "Adding organization:",
      this.state.organizationName,
      this.state.email,
      this.state.phoneNumber,
      this.state.address
    );

    this.setState({ openModal: false });

    this.setState({ organizationName: "", email: "", phone: "", address: "" });
  };

  componentDidMount() {
    // this.props.getAccessByUser(localStorage.getItem('token'), this.props.params.object_id)
    // const { search } = this.props.location
    if (this.props.location.search && this.props.location.search !== "") {
      
      const { search } = this.props.location;
      const params = new URLSearchParams(search);
      const encodedData = params.get('data');
      if (encodedData) {
        // 
        const { token, object_id } = JSON.parse(decodeURIComponent(encodedData));
        this.setState({ object_id });
        
        this.props.getAccessByUser(localStorage.getItem('token'), object_id);
        console.log("Access by user called with:", object_id, localStorage.getItem('token'));
      }
    } else {
      
      this.props.getAccessByUser(localStorage.getItem('token'), this.props.params.object_id);
      
      
    }


  }



  render() {
    const { openModal, openModal1, organizationName, email, phone, address } = this.state;

    return (

      <Grid
        container
        justifyContent="center"
        alignItems="center"
        style={{
          height: "100vh",
          backgroundColor: "#e6e6e9",
          overflow: "hidden",
          background:
            "linear-gradient(to right top, #c6ece5, #c7eee5, #c9f0e4, #cbf2e3, #cdf4e2, #cbf5e0, #caf5de, #c9f6dc, #c3f6d8, #bef6d5, #b8f6d1, #b3f6cd)",
        }}
      >


        {/* <div style={{ position: 'relative', height: '100vh' }}> */}
        {/* <Avatar
          alt="User Avatar"
          src={this.props.login.profile_pic}
          sx={{ width: 50, height: 50, mb: 0 }}
          style={{
            cursor: 'pointer',
            position: 'absolute',
            top: '10px',
            right: '20px'
          }}
          onClick={(e) => { this.setState({ modalIsOpen: true }) }}
        /> */}
        {/* </div> */}

        <Grid
          container
          justify="center"
          style={{ width: "60%", marginBottom: "20px" }}
        >
          <Grid
            item
            xs={12}
            className="parentOne"
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Card
              style={{
                display: "flex",
                width: "20%",
                borderRadius: "5px",
                justifyContent: "center",
                padding: "20px",
                alignItems: "center",
                transition: "transform 0.3s",
                "&:hover": { transform: "scale(1.05)" },
                width: "100%",
                height: "100%",
                marginTop: "-20px",
              }}
            >
              <Grid item xs={7.5} className="frame" style={{ width: "100%" }}>
                <Grid
                  container
                  direction="column"
                  alignItems="center"
                  justifyContent="center"
                  style={{ width: "100%" }}
                >
                  <Grid item xs={12}>
                    {/* <Link to="/Login"> */}
                    {/* <IconButton
                      onClick={()=>{
                        localStorage.removeItem('token')
                      }}
                      >
                        <ArrowBackRoundedIcon
                          cursor="pointer"
                          sx={{
                            marginLeft: "-300px",
                            alignItems: "left",
                            marginBottom: "-40px",
                          }}
                        />
                      </IconButton> */}
                    {/* </Link> */}
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginBottom: "20px",
                      marginTop: "-5px",
                    }}
                  >
                    <img
                      src={logo}
                      style={{
                        width: "30%",
                        height: "30%",
                        objectFit: "cover",
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    style={{
                      marginBottom: "2px",
                      width: "70%",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      variant="h5"
                      align="center"
                      style={{
                        fontWeight: "bold",

                        borderRadius: "10px",
                        width: "100%",
                        padding: "2px",
                        fontFamily: "sans-serif",
                        color: "#416165",
                        fontSize: "1.5rem",
                        flexGlow: "1",
                      }}
                    >
                      Organizations
                    </Typography>
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    style={{
                      marginBottom: "30px",
                      width: "70%",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      variant="h5"
                      align="center"
                      style={{
                        fontWeight: "400",

                        width: "100%",

                        fontFamily: "sans-serif",
                        color: "#929487",
                        fontSize: "1rem",
                        flexGlow: "1",
                      }}
                    >
                      Associated with this product
                    </Typography>
                  </Grid>

                  {Array.isArray(this.props.login.org) && this.props.login.org.map((item) => {
                    return (
                      <Grid
                        container
                        xs={12}
                        style={{
                          marginBottom: "20px",
                          width: "70%",
                          display: "flex",
                          alignItems: "center",

                          height: "20%",
                          borderBottom: "1px solid #66666e",
                          cursor: 'pointer'
                        }}
                        onClick={() => {
                          // this.props.selectOrg(localStorage.getItem('token'), item?.org_id?._id, this.props.params.object_id)
                          if (this.props.location.search && this.props.location.search !== "") {
                            // 
                            this.props.selectOrg(localStorage.getItem('token'), item?.org_id?._id, this.state.object_id)
                          }
                          else {
                            // 
                            this.props.selectOrg(localStorage.getItem('token'), item?.org_id?._id, this.props.params.object_id)
                          }
                        }}
                      >
                        <Grid
                          item
                          xs={3}
                          style={{
                            width: "100%",
                            padding: "10px",
                            fontFamily: "sans-serif",
                            color: "#66666e",
                            fontSize: "1rem",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "100%",
                            Padding: "5px",
                          }}
                        >
                          <Avatar
                            sx={{
                              width: 38,
                              height: 38,
                              backgroundColor: "#425a4f",
                              borderRadius: "50%",
                            }}
                            src={item?.org_id?.org_logo || 'T'}
                          >
                            {/* <AccountCircleIcon sx={{ fontSize: 40 }} /> */}
                          </Avatar>
                        </Grid>

                        <Grid
                          item
                          xs={9}
                          style={{
                            width: "100%",
                            padding: "10px",
                            fontFamily: "sans-serif",
                            color: "#66666e",
                            fontSize: "1rem",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "100%",
                            backgroundColor: "",
                          }}
                        >
                          <Typography
                            variant="h6"
                            style={{
                              fontSize: "1rem",
                              fontWeight: "600",
                              color: "#66666e",
                              fontFamily: "Roboto,sans-serif",
                              marginBottom: "5px",
                              padding: "5px",
                            }}
                          >
                            {item?.org_id?.org_name}
                          </Typography>
                        </Grid>
                      </Grid>
                    )
                  })}



                  <Grid container xs={12}>

                    <Grid
                      item
                      xs={6}
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginBottom: "20px",
                        width: "100%",
                      }}
                    >
                      <Tooltip title="ADD Organization" placement="bottom">
                        {/* <Link to="/addOrganization"> */}
                        <Button
                          variant="outlined"
                          onClick={() => {
                            this.handleOpenModal1()
                            this.props.getOwnedOrg(localStorage.getItem('token'))
                          }}
                          fullWidth
                          startIcon={<AddIcon />}
                          sx={{
                            backgroundColor: "#425a4f",
                            color: "white",
                            fontSize: "0.6rem",
                            borderRadius: "10px",
                            "&:hover": {
                              color: "white",
                              backgroundColor: "#3a3e3b",

                              border: "1px solid #626868",
                            },
                          }}
                        >
                          ADD Existing Organization
                        </Button>
                        {/* </Link> */}
                      </Tooltip>

                    </Grid>

                    <Grid
                      item
                      xs={6}
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginBottom: "20px",
                        width: "100%",
                      }}
                    >
                      <Tooltip title="Create Organization" placement="bottom">
                        <Button
                          variant="outlined"
                          onClick={this.handleOpenModal}
                          fullWidth
                          startIcon={<AddIcon />}
                          sx={{
                            color: "#425a4f",
                            borderColor: "#425a4f",
                            fontSize: "0.6rem",
                            borderRadius: "10px",
                            "&:hover": {
                              color: "white",
                              backgroundColor: "#3a3e3b",

                              border: "1px solid #626868",
                            },
                          }}
                        >
                          Create New Organization
                        </Button>
                      </Tooltip>
                      <Modal
                        aria-labelledby="transition-modal-title"
                        aria-describedby="transition-modal-description"
                        open={openModal}
                        onClose={this.handleCloseModal}
                      >
                        <Grid
                          sm={12}
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "100vh",
                          }}
                        >
                          <Card
                            style={{
                              padding: "20px",
                              width: "300px",
                              backgroundColor: "white",
                              boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.1)",
                            }}
                          >
                            <Typography
                              id="transition-modal-title"
                              variant="h6"
                              align="center"
                              gutterBottom
                              sx={{ color: "#425a4f" }}
                            >
                              Create Organization
                            </Typography>
                            <Grid item xs={12}>
                              <TextField
                                label="Name of the Organization"
                                variant="outlined"
                                fullWidth
                                margin="normal"
                                value={this.state.org_name}
                                onChange={(e) =>
                                  this.setState({ org_name: e.target.value })
                                }
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <TextField
                                label="Organization Email"
                                variant="outlined"
                                fullWidth
                                margin="normal"
                                value={this.state.org_email}
                                onChange={(e) =>
                                  this.setState({ org_email: e.target.value })
                                }
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <TextField
                                label="Organization Phone Number"
                                variant="outlined"
                                fullWidth
                                margin="normal"
                                type="number"
                                value={this.state.org_phone}
                                onChange={(e) =>
                                  this.setState({ org_phone: e.target.value })
                                }
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <TextField
                                label="Organization Address"
                                variant="outlined"
                                fullWidth
                                margin="normal"
                                value={this.state.org_address}
                                onChange={(e) =>
                                  this.setState({ org_address: e.target.value })
                                }
                              />
                            </Grid>

                            <Grid container spacing={2} justifyContent="center">
                              <Grid item>
                                <Button
                                  variant="contained"
                                  onClick={() => {
                                    if (this.props.location.search && this.props.location.search !== "") {
                                      
                                      this.props.createOrg(localStorage.getItem('token'), this.state.org_name, this.state.org_email, this.state.org_phone, this.state.org_address, this.state.object_id)
                                    }
                                    else {
                                      
                                      this.props.createOrg(localStorage.getItem('token'), this.state.org_name, this.state.org_email, this.state.org_phone, this.state.org_address, this.props.params.object_id)
                                    }
                                    this.setState({
                                      org_name: '', org_email: "", org_phone: '', org_address: '', openModal: false
                                    })
                                  }}
                                  sx={{
                                    backgroundColor: "#425a4f",
                                    color: "white",
                                    fontSize: "0.8rem",
                                    borderRadius: "5px",
                                    "&:hover": {
                                      color: "white",
                                      backgroundColor: "#3a3e3b",

                                      border: "1px solid #626868",
                                    },
                                  }}
                                >
                                  Create Organization
                                </Button>
                              </Grid>
                              <Grid item>
                                <Button
                                  variant="outlined"
                                  onClick={this.handleCloseModal}
                                  sx={{
                                    color: "#425a4f",
                                    borderColor: "#425a4f",
                                    fontSize: "0.8rem",
                                    fontWeight: "400",
                                    borderRadius: "5px",
                                    "&:hover": {
                                      color: "white",
                                      backgroundColor: "#3a3e3b",

                                      border: "1px solid #626868",
                                    },
                                  }}
                                >
                                  Cancel
                                </Button>
                              </Grid>
                            </Grid>
                          </Card>
                        </Grid>
                      </Modal>
                      <Modal
                        aria-labelledby="transition-modal-title"
                        aria-describedby="transition-modal-description"
                        open={openModal1}
                        onClose={this.handleCloseModal}
                      >
                        <Grid
                          sm={12}
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "100vh",
                          }}
                        >
                          <Card
                            style={{
                              padding: "20px",
                              width: "300px",
                              backgroundColor: "white",
                              boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.1)",
                            }}
                          >
                            <Typography
                              id="transition-modal-title"
                              variant="h6"
                              align="center"
                              gutterBottom
                              sx={{ color: "#425a4f" }}
                            >
                              Select Organization
                            </Typography>
                            {this.props.login.own_org?.map((item) => {
                              return (
                                <Grid
                                  container
                                  xs={12}
                                  style={{
                                    marginBottom: "20px",
                                    width: "70%",
                                    display: "flex",
                                    alignItems: "center",

                                    height: "20%",
                                    borderBottom: "1px solid #66666e",
                                    cursor: 'pointer'
                                  }}
                                  onClick={() => {
                                    if (this.props.location.search && this.props.location.search !== "") {
                                      this.props.createNewAccess(localStorage.getItem('token'), item?.org_id._id, this.state.object_id)
                                    }
                                    else {
                                      this.props.createNewAccess(localStorage.getItem('token'), item?.org_id._id, this.props.params.object_id)
                                    }
                                    this.setState({ openModal1: false })
                                  }}
                                >
                                  <Grid
                                    item
                                    xs={3}
                                    style={{
                                      width: "100%",
                                      padding: "10px",
                                      fontFamily: "sans-serif",
                                      color: "#66666e",
                                      fontSize: "1rem",
                                      display: "flex",
                                      flexDirection: "column",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      height: "100%",
                                      Padding: "5px",
                                    }}
                                  >
                                    <Avatar
                                      sx={{
                                        width: 38,
                                        height: 38,
                                        backgroundColor: "#425a4f",
                                        borderRadius: "50%",
                                      }}
                                      src={item?.org_id.org_logo || 'T'}
                                    >
                                      {/* <AccountCircleIcon sx={{ fontSize: 40 }} /> */}
                                    </Avatar>
                                  </Grid>

                                  <Grid
                                    item
                                    xs={9}
                                    style={{
                                      width: "100%",
                                      padding: "10px",
                                      fontFamily: "sans-serif",
                                      color: "#66666e",
                                      fontSize: "1rem",
                                      display: "flex",
                                      flexDirection: "column",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      height: "100%",
                                      backgroundColor: "",
                                    }}
                                  >
                                    <Typography
                                      variant="h6"
                                      style={{
                                        fontSize: "1rem",
                                        fontWeight: "600",
                                        color: "#66666e",
                                        fontFamily: "Roboto,sans-serif",
                                        marginBottom: "5px",
                                        padding: "5px",
                                      }}
                                    >
                                      {item?.org_id.org_name}
                                    </Typography>
                                  </Grid>
                                </Grid>
                              )
                            })}

                            <Grid container spacing={2} justifyContent="center">
                              <Grid item>
                                <Button
                                  variant="outlined"
                                  onClick={this.handleCloseModal1}
                                  sx={{
                                    color: "#425a4f",
                                    borderColor: "#425a4f",
                                    fontSize: "0.8rem",
                                    fontWeight: "400",
                                    borderRadius: "5px",
                                    "&:hover": {
                                      color: "white",
                                      backgroundColor: "#3a3e3b",

                                      border: "1px solid #626868",
                                    },
                                  }}
                                >
                                  Cancel
                                </Button>
                              </Grid>
                            </Grid>
                          </Card>
                        </Grid>
                      </Modal>

                    </Grid>



                  </Grid>
                  <Modal
                    open={this.state.modalIsOpen}
                    onClose={(e) => {
                      this.setState({ modalIsOpen: false })
                    }}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                  >
                    <Box
                      sx={{
                        position: 'absolute',
                        top: '80px', // Adjust as needed for your layout
                        right: '50px', // Adjust as needed for your layout
                        width: 300,
                        backgroundColor: 'white',
                        padding: 2,
                        borderRadius: 2,
                      }}
                    >
                      <center>
                        <Typography variant="h6">{this.props.email}</Typography>
                        {/* <Typography variant="h6">kumar.deepak@technoboot.co</Typography> */}
                        <Avatar
                          alt="User Avatar"
                          src={this.props.login.profile_pic}
                          sx={{ width: 70, height: 70, mb: 2 }}
                        />
                        <Typography variant="subtitle1">{this.props.login.name}</Typography>
                        {/* <Typography variant="subtitle1">Deepak</Typography> */}
                      </center>
                      <center>
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'center',

                          }}
                        >
                          {/* <Link to="/"> */}
                          <Button
                            variant="contained"
                            sx={{
                              borderRadius: 1,
                              backgroundColor: '#3D3B3B',
                              width: 150,
                              height: 40,
                              color: 'white',
                            }}
                            onClick={() => {
                              this.props.logOutByToken(this.props.login.token)
                              this.setState({ modalIsOpen: false })
                            }}
                          >
                            Logout
                          </Button>
                          {/* </Link> */}
                        </Box>
                      </center>
                    </Box>
                  </Modal>



                </Grid>
              </Grid>
              <Grid
                item
                xs={4.5}
                className="frame"
                style={{
                  borderRadius: "5px",
                  width: "100%",
                  marginBottom: "10px",
                }}
              >
                <img
                  src={organization}
                  style={{ width: "100%", height: "100%", objectFit: "cover" }}
                />
              </Grid>
            </Card>
          </Grid>
        </Grid>
        <LoaderCon />
      </Grid>
    );
  }
}
export default withParams(Organization)
