import React, { useEffect, useRef, useState } from 'react';
import { Grid, Typography, Avatar, IconButton, Divider, TextField, Button, Alert, Snackbar } from '@mui/material';
import { Edit, CloseCircle, TickSquare } from 'iconsax-react';
import CustomizedSnackbars from '../../../../snackbar/Snackbar';
const Basic = (props) => {
  const user_data = props.user_data;

  const [pro_pic, setPro_pic] = useState('');
  const fileInputRef = useRef(null);
  const [pro_pic_edit, setPro_pic_edit] = useState(false);
  const [name, setName] = useState('');
  const [name_edit, setName_edit] = useState(false);
  const [email, setEmail] = useState('');
  const [email_edit, setEmail_edit] = useState(false);
  const [mobile, setMobile] = useState('');
  const [mobile_edit, setMobile_edit] = useState(false);
  const [otpStage, setOtpStage] = useState(false);
  const [otp, setOtp] = useState('');
  const [alertOpen, setAlertOpen] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  
  const snackbarProps = {
    setSnackbarOpen,
    setSnackbarMessage,
    setSnackbarSeverity,
  };

  const handleVerifyClick = (type) => {
    if (type === 'email') {
      props.sendOtpToEmail(props.login.token, snackbarProps)
    }
    else {
      props.sendOtpToMobile(props.login.token, mobile, snackbarProps)
    }
  };

  useEffect(() => {
    props.resetOtpVerification()
  }, []);

  const handleClear = () => {
    props.resetOtpVerification()
  };

  const handleEmailOtpSubmit = () => {
    if (otp === '') {
      setAlertOpen(true);
      return;
    }
    props.verifyEmail(props.login.token, otp);
  };

  const handleEmailChange = () => {
    if (otp === '') {
      setAlertOpen(true);
      return;
    }
    props.updateEmail(props.login.token, email);
  };

  const handleNameChange = () => {
    props.updateName(props.login.token, name);
  };

  const handleMobileOtpSubmit = () => {
    if (otp === '') {
      setAlertOpen(true);
      return;
    }
    props.verifyMobile(props.login.token, mobile, otp);
  };

  const handleMobileChange = () => {
    if (otp === '') {
      setAlertOpen(true);
      return;
    }
    props.updateMobile(props.login.token, mobile);
  };

  const handleProChange = () => {
    props.updateProfile(props.login.token, pro_pic);
    setPro_pic_edit(false)
  };

  const handleCloseAlert = () => {
    setAlertOpen(false);
  };

  return (
    <Grid pt={5} pb={5} style={{
      backgroundImage: 'linear-gradient(to bottom, rgba(209, 116, 7, 0.1), rgba(255, 255, 255, 0))',
      borderTopLeftRadius: '50px',
      borderTopRightRadius: '50px',
    }}>
      <center>
        <Typography className='text' variant="h5">Personal info</Typography>
        <Typography className='text' fontSize={'12px'} m={1}>
          Info about you and your preferences across services.
        </Typography>
      </center>
      <Snackbar open={alertOpen} autoHideDuration={1000} onClose={handleCloseAlert}>
        <Alert severity="warning" onClose={handleCloseAlert}>
          Invalid Otp....!
        </Alert>
      </Snackbar>
      <Grid container mt={5}>
        <Grid item xs={1.5} />
        <Grid item xs={9}>
          <Typography className='text' variant="h6">
            Your profile info in Technoboot services
          </Typography>
          <Typography className='text' fontSize={'12px'} color="textSecondary">
            Personal info and options to manage it...
          </Typography>

          <Grid mb={2} mt={2} p={2} style={{ borderRadius: '10px', border: '1px solid #ebecf0', backgroundColor: '#FFF', boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.1)" }}>
            <Grid container spacing={2} alignItems="center">
              {/* Profile Picture Section */}
              <Grid item xs={12} style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Grid>
                  <Typography className='text' variant="h6">Basic info</Typography>
                  <Typography className='text' fontSize={'12px'} color="textSecondary">
                    Some info may be visible to other people using Technoboot services.
                  </Typography>
                  <Divider style={{ width: '100%' }} />
                </Grid>
                <Grid alignItems="center" justifyContent="center" style={{ position: 'relative', width: 'fit-content' }}>
                  {pro_pic_edit ? (
                    <IconButton
                      onClick={() => {
                        setPro_pic_edit(false);
                        setPro_pic('');
                      }}
                      style={{
                        position: 'absolute',
                        top: -10,
                        right: -10,
                        zIndex: 1,
                        pointerEvents: 'auto',
                      }}
                    >
                      <CloseCircle color="red" />
                    </IconButton>
                  ) : (
                    <IconButton
                      onClick={() => {
                        fileInputRef.current.click(); // Trigger file input click
                      }}
                      style={{
                        position: 'absolute',
                        top: -10,
                        right: -10,
                        zIndex: 1,
                        pointerEvents: 'auto',
                      }}
                    >
                      <Edit />
                    </IconButton>
                  )}

                  <Avatar
                    src={pro_pic !== '' ? URL.createObjectURL(pro_pic) : user_data?.profile_pic}
                    style={{ width: 50, height: 50 }}
                  />

                  {pro_pic_edit && (
                    <IconButton
                      onClick={(e) => {
                        e.stopPropagation(); // Prevent triggering file input
                        handleProChange();
                      }}
                      style={{
                        position: 'absolute',
                        bottom: -10,
                        right: -10,
                        zIndex: 2,
                      }}
                    >
                      <TickSquare color="green" />
                    </IconButton>
                  )}

                  <input
                    style={{ display: 'none' }}
                    type="file"
                    id="file"
                    ref={fileInputRef} // Attach ref to file input
                    onChange={(e) => {
                      if (e.target.files && e.target.files.length > 0) {
                        setPro_pic(e.target.files[0]);
                        setPro_pic_edit(true); // Set editing state after file selection
                      }
                    }}
                  />
                </Grid>
              </Grid>

              {/* Name Section */}
              <Grid item xs={12} style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Grid>
                  <Typography className='text'>Full Name</Typography>
                  <Typography className='text' fontSize={'12px'} color="textSecondary">
                    {user_data?.name}
                  </Typography>
                  {name_edit &&
                    <TextField size='small' variant='standard' value={name}
                      inputProps={{ style: { fontSize: '12px' } }}
                      onChange={(e) => setName(e.target.value)} />
                  }
                </Grid>
                <Grid>
                  <IconButton onClick={() => setName_edit(!name_edit)}>
                    {name_edit ?
                      <>
                        <Button onClick={handleNameChange}>Save Change</Button>
                        <CloseCircle color='red' />
                      </>
                      :
                      <Edit />
                    }
                  </IconButton>
                </Grid>

              </Grid>

              {/* Email Section */}
              <Grid item xs={12} style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Grid component={'form'}>
                  <Typography className='text'>Email Id</Typography>
                  <Typography className='text' fontSize={'12px'} color="textSecondary">
                    {user_data?.email_id}
                  </Typography>

                  {!email_edit && props.login.email_otp && (
                    <>
                      {props.login.email_verification ? (
                        <TextField
                          size="small"
                          variant="standard"
                          value={email}
                          placeholder="Enter New Email ID"
                          inputProps={{ style: { fontSize: '12px' } }}
                          onChange={(e) => setEmail(e.target.value)}
                          required
                        />
                      ) : (
                        <TextField
                          size="small"
                          variant="standard"
                          value={otp}
                          placeholder="Enter OTP"
                          inputProps={{ style: { fontSize: '12px' } }}
                          onChange={(e) => setOtp(e.target.value)}
                        />
                      )}
                    </>
                  )}


                </Grid>
                <Grid>
                  {!email_edit && (
                    props.login.email_otp ? (
                      <>
                        {props.login.email_verification ?
                          <Button onClick={handleEmailChange}>Save Change</Button> :
                          <><Button onClick={handleEmailOtpSubmit}>Submit OTP</Button>
                            <Button onClick={handleClear}>Clear</Button> </>
                        }
                      </>
                    ) :
                      <IconButton onClick={() => handleVerifyClick('email')}>
                        {<Edit />}
                      </IconButton>
                  )}
                </Grid>
              </Grid>

              {/* Mobile Section */}
              <Grid item xs={12} style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Grid>
                  <Typography className='text'>Phone Number</Typography>
                  <Typography className='text' fontSize={'12px'} color="textSecondary">
                    {user_data?.mobile}
                  </Typography>

                  {!mobile_edit && props.login.mobile_otp && (
                    <>
                      {props.login.mobile_verification ? (
                        <TextField
                          size="small"
                          variant="standard"
                          value={mobile}
                          placeholder="Enter New Mobile Number"
                          inputProps={{ style: { fontSize: '12px' } }}
                          onChange={(e) => setMobile(e.target.value)}
                          required
                        />
                      ) : (
                        <TextField
                          size="small"
                          variant="standard"
                          value={otp}
                          placeholder="Enter OTP"
                          inputProps={{ style: { fontSize: '12px' } }}
                          onChange={(e) => setOtp(e.target.value)}
                        />
                      )}
                    </>
                  )}

                </Grid>
                <Grid>

                  {!mobile_edit && (
                    props.login.mobile_otp ? (
                      <>
                        {props.login.mobile_verification ?
                          <Button onClick={handleMobileChange}>Save Change</Button> :
                          <><Button onClick={handleMobileOtpSubmit}>Submit OTP</Button>
                            <Button onClick={handleClear}>Clear</Button> </>
                        }
                      </>
                    ) :
                      <IconButton onClick={() => handleVerifyClick('mobile')}>
                        {<Edit />}
                      </IconButton>
                  )}

                </Grid>
              </Grid>

            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={1.5} />
        <CustomizedSnackbars
          message={props.snackbar.message}
          openSnackbar={props.snackbar.response_received}
          severity={props.snackbar.severity}
        />
      </Grid>
    </Grid >
  );
};

export default Basic;
