import React, { Component } from "react";
import Router from "./router/containers/router_cont";
class App extends Component {
  render() {
    return (
        <Router/>
    );
  }
}
export default App;
