
import React, { Component } from 'react';
import { Grid, Avatar, Typography, Button, Modal, Card } from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';



export default class ShowProfile extends Component {
    constructor(props) {
        super(props);
        this.state = {
         
          openModal: false,
         
        };
      }

      handleOpenModal = () => {
        this.setState({ openModal: true });
      };
    
      handleCloseModal = () => {
        this.setState({ openModal: false });
      };
    

  render() {
    const { organization } = this.props;
    const {openModal} = this.state;
    
    return (
      <Grid
        container
        className="showProfile"
        xs={12}
        style={{
          marginBottom: '20px',
          width: '70%',
          display: 'flex',
          alignItems: 'center',
          marginTop: '5px',
          height: '20%',
          borderBottom: '1px solid #66666e',
          
        }}
      >
        <Grid
          item
          xs={3}
          style={{
            width: '100%',
            padding: '10px',
            fontFamily: 'sans-serif',
            color: '#66666e',
            fontSize: '1rem',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
            Padding: '5px',
          }}
        >
          <Avatar
            src={organization.avatar}
            sx={{
              width: 38,
              height: 38,
              backgroundColor: '#425a4f',
              borderRadius: '50%',
            }}
          >
            {!organization.avatar && <AccountCircleIcon sx={{ fontSize: 40 }} />}
          </Avatar>
        </Grid>

        <Grid
          item
          xs={9}
          style={{
            width: '100%',
            padding: '10px',
            fontFamily: 'sans-serif',
            color: '#66666e',
            fontSize: '1rem',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
            backgroundColor: '',
          }}
        >
          <Typography
            variant="h6"
            style={{
              fontSize: '1rem',
              fontWeight: '600',
              color: '#66666e',
              fontFamily: 'Roboto,sans-serif',
              marginBottom: '5px',
              padding: '5px',
            }}
            onClick={this.handleOpenModal}
          >
            {organization.name}
          </Typography>

          
                     
          <Modal
                        aria-labelledby="transition-modal-title"
                        aria-describedby="transition-modal-description"
                        open={openModal}
                        onClose={this.handleCloseModal}
                      >
                        <Grid
                          sm={12}
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "100vh",
                          }}
                        >
                          <Card
                            style={{
                              padding: "20px",
                              width: "300px",
                              backgroundColor: "white",
                              boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.1)",
                            }}
                          >
                            <Typography
                              id="transition-modal-title"
                              variant="h6"
                              align="center"
                              gutterBottom
                              sx={{ color: "#6c756b", fontSize: "1rem", fontWeight: "400" }}
                            >
                              Are you sure you want to add this organization
                            </Typography>
                            
                            <Grid container spacing={2} justifyContent="center">
                              <Grid item>
                                <Button
                                  variant="contained"
                                  onClick={this.handleCloseModal}
                                  sx={{
                                    backgroundColor: "#cad5ca",
                                    color: "#3a3e3b",
                                    "&:hover": {
                                      color: "white",
                                      backgroundColor: "#3a3e3b",

                                      border: "1px solid #626868",
                                    },
                                  }}
                                >
                                  Add 
                                </Button>
                              </Grid>
                              <Grid item>
                                <Button
                                  variant="outlined"
                                  onClick={this.handleCloseModal}
                                  sx={{
                                    color: "#425a4f",
                                    borderColor: "#425a4f",
                                    "&:hover": {
                                        color: "white",
                                        backgroundColor: "#3a3e3b",
  
                                        border: "1px solid #626868",
                                      },
                                  }}
                                >
                                  Cancel
                                </Button>
                              </Grid>
                            </Grid>
                          </Card>
                        </Grid>
                      </Modal>
        </Grid>
      </Grid>
    );
  }
}
