import * as CONSTANT from "./Constant";
import UNIVERSAL from "../../config/config";
import {
    LOGIN,
    LOGOUT,
    USER_DETAILS
} from "./Constant";
import { setLoader, unsetLoader } from "../../../src/loader/action";
import 'firebase/storage';
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { close_snack_bar, set_snack_bar } from "../../snackbar/snackbar_action";

// export function getUser(token) {
//     return (dispatch) => {
//         // dispatch(setLoader());
//         return fetch(UNIVERSAL.BASEURL + "getUserToken", {
//             method: "GET",
//             headers: {
//                 Accept: "application/json",
//                 "Content-Type": "application/json",
//                 token: token,
//             }
//         })
//             .then((response) => response.json())
//             .then((responseJson) => {
//                 if (responseJson.statusCode === 200 || responseJson.statusCode === 201) {
//                     dispatch({ type: CONSTANT.GETUSER, payload: responseJson.result })
//                     //dispatch(unsetLoader());
//                 }
//                 else if (responseJson.statusCode === (401 || 403 || 409)) {
//                     // dispatch(onLogout())
//                     // dispatch(unsetLoader());
//                 }
//                 else {
//                     // dispatch(unsetLoader());
//                 }
//             })
//             .catch((error) => {
//                 // dispatch(unsetLoader());
//                 // dispatch(set_snack_bar(true, "Check Your Internet Connection"));
//                 console.error(error);
//             });
//     };
// }
export function login_by_email(email, login_id) {
    return (dispatch) => {
        dispatch(setLoader());

        return fetch(UNIVERSAL.BASEURL + "api/v1/auth/email_login", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                email, login_id
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.statusCode === 200 || responseJson.statusCode === 201) {
                    dispatch({ type: CONSTANT.OTP, payload: true })
                    dispatch({ type: USER_DETAILS, payload: responseJson.data })
                    dispatch(unsetLoader());
                }
                else {
                    dispatch({ type: CONSTANT.OTP, payload: false })
                    dispatch({ type: USER_DETAILS, payload: [] })

                    dispatch(unsetLoader());
                }
            })
            .catch((error) => {
                dispatch(unsetLoader());
                // dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}

export function verify_otp_email(email, otp, login_id) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "api/v1/auth/verify_email_otp", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                email, otp, login_id
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.statusCode === 200 || responseJson.statusCode === 201) {
                    if (responseJson.data.profile) {
                        localStorage.setItem('profile', responseJson.data.profile)
                        localStorage.setItem('token', responseJson.data.token)
                        dispatch({ type: CONSTANT.PROFILE_VIEW, payload: responseJson.data })
                        dispatch(unsetLoader());
                    }
                    else {
                        if (responseJson.data.origin) {
                            localStorage.setItem('token', responseJson.data.token)
                            window.location.href = (responseJson.data.origin + "/verify/" +
                                responseJson.data.token
                            )

                        }
                        else {
                            localStorage.setItem('token', responseJson.data.token)
                            dispatch({ type: CONSTANT.TOKEN, payload: responseJson.data.token })
                            dispatch(unsetLoader());
                        }
                    }

                }
                else {
                    dispatch({ type: CONSTANT.OTP, payload: false })
                    dispatch(unsetLoader());
                }
            })
            .catch((error) => {
                dispatch(unsetLoader());
                // dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}

export function verify_otp_email_new_user(email, otp, login_id, mobile, name) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "api/v1/auth/verify_otp_email_new_user", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                email, otp, login_id, mobile, name
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.statusCode === 200 || responseJson.statusCode === 201) {
                    if (responseJson.data.profile) {
                        localStorage.setItem('profile', responseJson.data.profile)
                        localStorage.setItem('token', responseJson.data.token)
                        dispatch({ type: CONSTANT.PROFILE_VIEW, payload: responseJson.data })
                        dispatch(unsetLoader());
                    }
                    else {
                        if (responseJson.data.origin) {
                            localStorage.setItem('token', responseJson.data.token)
                            window.location.href = (responseJson.data.origin + "/verify/" +
                                responseJson.data.token
                            )

                        }
                        else {

                            localStorage.setItem('token', responseJson.data.token)
                            dispatch({ type: CONSTANT.TOKEN, payload: responseJson.data.token })
                            dispatch(unsetLoader());
                        }
                    }

                }
                else {
                    dispatch({ type: CONSTANT.OTP, payload: false })
                    dispatch(unsetLoader());
                }
            })
            .catch((error) => {
                dispatch(unsetLoader());
                // dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}

export function checkToken(token) {
    return (dispatch) => {
        // dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "api/v1/auth/check_token", {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                token: token
            }
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.statusCode === 200 || responseJson.statusCode === 201) {

                    if (responseJson.data.domain.single) {
                        localStorage.setItem('token', token)
                        window.location.href = (responseJson.data.origin + "/verify/" +
                            token
                        )
                    }
                    else {
                        localStorage.setItem('token', token)
                        dispatch({ type: CONSTANT.TOKEN, payload: token })
                    }
                }
                else {
                    dispatch({ type: CONSTANT.OTP, payload: false })
                }
            })
            .catch((error) => {
                // dispatch(unsetLoader());
                // dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}

export function checkObjectId(token, object_id) {
    return (dispatch) => {
        // dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "api/v1/auth/check_object_id", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                token: token
            },
            body: JSON.stringify({
                object_id
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.statusCode === 200 || responseJson.statusCode === 201) {

                    if (responseJson.data.domain.single) {
                        localStorage.setItem('token', token)
                        window.location.href = (responseJson.data.origin + "/verify/" +
                            token
                        )
                    }
                    else {
                        localStorage.setItem('token', token)
                        dispatch({ type: CONSTANT.TOKEN, payload: token })
                    }
                }
                else {
                    dispatch({ type: CONSTANT.OTP, payload: false })
                }
            })
            .catch((error) => {
                // dispatch(unsetLoader());
                // dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}

export function login_by_mobile(mobile, login_id) {
    return (dispatch) => {
        // dispatch(setLoader());

        return fetch(UNIVERSAL.BASEURL + "api/v1/auth/phone_login", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                mobile: '91' + mobile, login_id
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.statusCode === 200 || responseJson.statusCode === 201) {
                    dispatch({ type: CONSTANT.OTP, payload: true })
                }
                else {
                    dispatch({ type: CONSTANT.OTP, payload: false })
                }
            })
            .catch((error) => {
                // dispatch(unsetLoader());
                // dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}

export function verify_otp_mobile(mobile, otp, login_id) {
    return (dispatch) => {
        // dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "api/v1/auth/verify_mobile_otp", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                mobile: '91' + mobile, otp, login_id
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.statusCode === 200 || responseJson.statusCode === 201) {

                    if (responseJson.data.origin) {
                        localStorage.setItem('token', responseJson.data.token)
                        window.location.href = (responseJson.data.origin + "/verify/" +
                            responseJson.data.token
                        )
                    }
                    else {
                        localStorage.setItem('token', responseJson.data.token)
                        dispatch({ type: CONSTANT.TOKEN, payload: responseJson.data.token })
                    }
                }
                else {
                    dispatch({ type: CONSTANT.OTP, payload: false })
                }
            })
            .catch((error) => {
                // dispatch(unsetLoader());
                // dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}

export function create_login() {
    return (dispatch) => {
        // dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "api/v1/loginActivity/createLogin", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                product: "66450425135987f133808493"
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.statusCode === 200 || responseJson.statusCode === 201) {

                    if (responseJson.data.origin) {
                        localStorage.setItem('token', responseJson.data.token)
                        window.location.href = (responseJson.data.origin + "/verify/" +
                            responseJson.data.token
                        )
                    }
                    {
                        localStorage.setItem('token', responseJson.data.token)
                        dispatch({ type: CONSTANT.TOKEN, payload: responseJson.data.token })
                    }
                }
                else {
                    dispatch({ type: CONSTANT.OTP, payload: false })
                }
            })
            .catch((error) => {
                // dispatch(unsetLoader());
                // dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}

export function setOtp(value) {
    return (
        {
            type: CONSTANT.OTP,
            payload: value
        }
    )
}

export function getAccessByUser(token, object_id) {

    return (dispatch) => {

        return fetch(UNIVERSAL.BASEURL + "api/v1/access/getAccessByUser", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                token: token,
            },
            body: JSON.stringify({
                object_id: object_id
            })

        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.statusCode === 200 || responseJson.statusCode === 201) {
                    dispatch({ type: CONSTANT.ORG, payload: responseJson.data.access })
                }
                else if (responseJson.statusCode === 401) {
                    localStorage.removeItem('token')
                    dispatch(onLogout())
                }
                else {
                    dispatch({ type: CONSTANT.ORG, payload: [] })
                }
            })
            .catch((error) => {
                // dispatch(unsetLoader());
                // dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}

export function getOwnedOrg(token) {
    return (dispatch) => {
        return fetch(UNIVERSAL.BASEURL + "api/v1/access/getOwnedOrg", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                token: token
            }
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.statusCode === 200 || responseJson.statusCode === 201) {
                    dispatch({ type: CONSTANT.OWN_ORG, payload: responseJson.data })
                }
                else if (responseJson.statusCode === 401) {
                    localStorage.removeItem('token')
                    dispatch(onLogout())
                }
                else {
                    dispatch({ type: CONSTANT.OWN_ORG, payload: [] })
                }
            })
            .catch((error) => {
                // dispatch(unsetLoader());
                // dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}

export function createNewAccess(token, org_id, object_id) {
    return (dispatch) => {
        return fetch(UNIVERSAL.BASEURL + "api/v1/access/createNewAccess", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                token: token
            },
            body: JSON.stringify({
                org_id,
                object_id: object_id
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.statusCode === 200 || responseJson.statusCode === 201) {
                    dispatch(getAccessByUser(token, object_id))
                }
                else if (responseJson.statusCode === 401) {
                    localStorage.removeItem('token')
                    dispatch(onLogout())
                }
                else {

                }
            })
            .catch((error) => {
                // dispatch(unsetLoader());
                // dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}

export function createOrg(token, org_name, org_email, org_phone, org_address, object_id) {
    return (dispatch) => {
        return fetch(UNIVERSAL.BASEURL + "api/v1/org/createOrg", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                token: token
            },
            body: JSON.stringify({
                org_name, org_email, org_phone, org_address, object_id
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.statusCode === 200 || responseJson.statusCode === 201) {
                    dispatch(getAccessByUser(token, object_id));
                }
                else if (responseJson.statusCode === 401) {
                    localStorage.removeItem('token')
                    dispatch(onLogout())
                }
                else {
                    dispatch(getAccessByUser(token, object_id));
                }
            })
            .catch((error) => {
                // dispatch(unsetLoader());
                // dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}

export function selectOrg(token, org_id, object_id) {

    return (dispatch) => {
        return fetch(UNIVERSAL.BASEURL + "api/v1/org/selectOrg", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                token: token,
            },
            body: JSON.stringify({
                org_id, org_id,
                object_id: object_id
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.statusCode === 200 || responseJson.statusCode === 201) {
                    window.location.href = (responseJson.data.product_url + "/verify/" +
                        token
                    )
                }
                else if (responseJson.statusCode === 401) {
                    localStorage.removeItem('token')
                    dispatch(onLogout())
                }
            })
            .catch((error) => {
                // dispatch(unsetLoader());
                // dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}

export function fetchToken(token, object_id) {
    return (dispatch) => {
        // 
        // dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "api/v1/access/getAccessByUser", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                token: token,
            },
            body: JSON.stringify({
                object_id: object_id
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.statusCode === 200 || responseJson.statusCode === 201) {
                    dispatch(setTokenData(responseJson.data))
                    // localStorage.setItem("token", responseJson.data.token);
                    // dispatch(unsetLoader());
                }
                else if (responseJson.statusCode === (401 || 403 || 409)) {
                    dispatch(onLogout())
                    // dispatch(unsetLoader());
                }
                else {
                    // dispatch(unsetLoader());
                }
            })
            .catch((error) => {
                // dispatch(unsetLoader());
                // dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}

export function setTokenData(payload) {
    payload.user_id.token = payload.token;
    payload.user_id.org_id = payload.org_id._id;
    localStorage.setItem("token", payload.token);
    return {
        type: LOGIN,
        payload: payload.user_id,
    };
}

export function logOutByToken(token) {
    return (dispatch) => {

        // dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "api/v1/auth/logout_by_token", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                token: token,
            }),
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.statusCode == (200 || 201)) {

                    localStorage.removeItem("token");
                    dispatch(onLogout())
                } else {
                    dispatch((responseJson.status, responseJson.message));

                    dispatch(onLogout())
                }
                // dispatch(set_snack_bar(true, responseJson.message));

            })
            .catch((error) => {
                // dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}

export function onLogout() {

    localStorage.removeItem("token");
    // window.location.href='/'
    return {
        type: LOGOUT,
    };
}

export function getUserData(token) {
    return (dispatch) => {
        // 
        // dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "api/v1/loginActivity/userDetailsByToken", {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                token: token,
            }
        })
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.statusCode === 200 || responseJson.statusCode === 201) {
                    dispatch({ type: CONSTANT.USER_DATA, payload: responseJson.data })
                    dispatch(unsetLoader());
                }
                else if (responseJson.statusCode === (401 || 403 || 409)) {
                    dispatch(onLogout())
                    dispatch(unsetLoader());
                }
                else {
                    dispatch(unsetLoader());
                }
            })
            .catch((error) => {
                // dispatch(unsetLoader());
                // dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}

export function getDeviceData(token) {
    return (dispatch) => {
        // 
        // dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "api/v1/loginActivity/deviceDetailsByToken", {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                token: token,
            }
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.statusCode === 200 || responseJson.statusCode === 201) {
                    console.log(responseJson.data)
                    dispatch({ type: CONSTANT.DEVICE_DATA, payload: responseJson.data })
                    dispatch(unsetLoader());
                }
                else if (responseJson.statusCode === (401 || 403 || 409)) {
                    dispatch(onLogout())
                    dispatch(unsetLoader());
                }
                else {
                    dispatch(unsetLoader());
                }
            })
            .catch((error) => {

                // dispatch(unsetLoader());
                // dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}

export function getOrgdata(token) {
    return (dispatch) => {
        // 
        // dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "api/v1/loginActivity/orgDetailsByToken", {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                token: token,
            }
        })
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.statusCode === 200 || responseJson.statusCode === 201) {
                    dispatch({ type: CONSTANT.ORG_DATA, payload: responseJson.data })
                    dispatch(unsetLoader());
                }
                else if (responseJson.statusCode === (401 || 403 || 409)) {
                    dispatch(onLogout())
                    dispatch(unsetLoader());
                }
                else {
                    dispatch(unsetLoader());
                }
            })
            .catch((error) => {
                // dispatch(unsetLoader());
                // dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}

export function updateProfile(token, profile_pic) {
    return (dispatch) => {
        dispatch(setLoader());

        const uniqueFilename = `/Profile_Pic/${profile_pic.name}_${Date.now()}.png`;
        const storageRef = ref(getStorage(), uniqueFilename);
        const uploadProfile = uploadBytesResumable(storageRef, profile_pic);

        uploadProfile.on(
            "state_changed",
            (snapshot) => {
                // Optional: Track upload progress if needed
            },
            (error) => {
                // Handle upload error
                dispatch(unsetLoader());
                console.error("Upload Error:", error);
            },
            () => {
                // Upload completed successfully
                getDownloadURL(uploadProfile.snapshot.ref).then((downloadURL) => {
                    if (downloadURL) {
                        fetch(UNIVERSAL.BASEURL + "api/v1/auth/updateUserByToken", {
                            method: "POST",
                            headers: {
                                Accept: "application/json",
                                "Content-Type": "application/json",
                                token: token
                            },
                            body: JSON.stringify({
                                profile_pic: downloadURL
                            })
                        })
                            .then((response) => response.json())
                            .then((responseJson) => {
                                if (responseJson.statusCode === 200 || responseJson.statusCode === 201) {
                                    dispatch(getUserData(token));
                                    dispatch(resetOtpVerification());
                                } else if (responseJson.statusCode === 401) {
                                    localStorage.removeItem('token');
                                    dispatch(onLogout());
                                } else {
                                    dispatch(getUserData(token));
                                }
                            })
                            .catch((error) => {
                                dispatch(unsetLoader());
                                console.error("API Error:", error);
                            });
                    } else {
                        console.error("Failed to get download URL");
                        dispatch(unsetLoader());
                    }
                }).catch((error) => {
                    dispatch(unsetLoader());
                    console.error("Error fetching download URL:", error);
                });
            }
        );
    };
}

export function updateEmail(token, email_id) {
    return (dispatch) => {
        return fetch(UNIVERSAL.BASEURL + "api/v1/auth/updateUserByToken", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                token: token
            },
            body: JSON.stringify({
                email_id
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.statusCode === 200 || responseJson.statusCode === 201) {
                    dispatch(getUserData(token));
                    dispatch(resetOtpVerification())
                }
                else if (responseJson.statusCode === 401) {
                    localStorage.removeItem('token')
                    dispatch(onLogout())
                }
                else {
                    dispatch(getUserData(token));
                }
            })
            .catch((error) => {
                dispatch(unsetLoader());
                console.error(error);
            });
    };
}
export function updateMobile(token, mobile) {
    return (dispatch) => {
        return fetch(UNIVERSAL.BASEURL + "api/v1/auth/updateUserByToken", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                token: token
            },
            body: JSON.stringify({
                mobile
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.statusCode === 200 || responseJson.statusCode === 201) {
                    dispatch(getUserData(token));
                    dispatch(resetOtpVerification())
                }
                else if (responseJson.statusCode === 401) {
                    localStorage.removeItem('token')
                    dispatch(onLogout())
                }
                else {
                    dispatch(getUserData(token));
                }
            })
            .catch((error) => {
                dispatch(unsetLoader());
                console.error(error);
            });
    };
}
export function updateName(token, name) {
    return (dispatch) => {
        return fetch(UNIVERSAL.BASEURL + "api/v1/auth/updateUserByToken", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                token: token
            },
            body: JSON.stringify({
                name
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.statusCode === 200 || responseJson.statusCode === 201) {
                    dispatch(getUserData(token));
                    dispatch(resetOtpVerification())
                }
                else if (responseJson.statusCode === 401) {
                    localStorage.removeItem('token')
                    dispatch(onLogout())
                }
                else {
                    dispatch(getUserData(token));
                }
            })
            .catch((error) => {
                dispatch(unsetLoader());
                console.error(error);
            });
    };
}

export function sendOtpToEmail(token) {
    return (dispatch) => {
        dispatch(setLoader());

        return fetch(UNIVERSAL.BASEURL + "api/v1/auth/sendEmailOTP", {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                token: token
            }
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.statusCode === 200 || responseJson.statusCode === 201) {
                    dispatch({ type: CONSTANT.EMAIL_OTP, payload: true })
                    dispatch(set_snack_bar(true, 'OTP sent successfully!', 'success'))
                    dispatch(unsetLoader());
                }
                else {
                    dispatch({ type: CONSTANT.EMAIL_OTP, payload: false })
                    dispatch(set_snack_bar(true, 'Failed to send OTP. Please try again.', 'error'))

                    dispatch(unsetLoader());
                }
            })
            .catch((error) => {
                dispatch(set_snack_bar(true, 'An error occurred while sending OTP.', 'error'))
                dispatch(unsetLoader());
                // dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}

export function verifyEmail(token, otp) {
    return (dispatch) => {
        dispatch(setLoader());

        return fetch(UNIVERSAL.BASEURL + "api/v1/auth/verifyEmailOTP", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                token: token
            },
            body: JSON.stringify({
                otp
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {
                console.log(responseJson)
                if (responseJson.statusCode === 200 || responseJson.statusCode === 201) {
                    dispatch({ type: CONSTANT.EMAIL_VERIFICATION, payload: true })
                    dispatch(unsetLoader());
                }
                else {
                    dispatch({ type: CONSTANT.EMAIL_VERIFICATION, payload: false })

                    dispatch(unsetLoader());
                }
            })
            .catch((error) => {
                dispatch(unsetLoader());
                // dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}

export function sendOtpToMobile(token, mobile) {
    return (dispatch) => {
        dispatch(setLoader());

        return fetch(UNIVERSAL.BASEURL + "api/v1/auth/sendMobileOTP", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                token: token
            },
            body: JSON.stringify({
                mobile
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.statusCode === 200 || responseJson.statusCode === 201) {
                    dispatch({ type: CONSTANT.MOBILE_OTP, payload: true })
                    dispatch(set_snack_bar(true, 'OTP sent successfully!', 'success'))
                    dispatch(unsetLoader());
                }
                else {
                    dispatch({ type: CONSTANT.MOBILE_OTP, payload: false })
                    dispatch(set_snack_bar(true, 'Failed to send OTP. Please try again.', 'error'))
                    dispatch(unsetLoader());
                }
            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, 'An error occurred while sending OTP.', 'error'))
                // dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}

export function verifyMobile(token, mobile, otp) {
    return (dispatch) => {
        dispatch(setLoader());

        return fetch(UNIVERSAL.BASEURL + "api/v1/auth/verifyMobileOTP", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                token: token
            },
            body: JSON.stringify({
                mobile, otp
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.statusCode === 200 || responseJson.statusCode === 201) {
                    dispatch({ type: CONSTANT.MOBILE_VERIFICATION, payload: true })
                    dispatch(unsetLoader());
                }
                else {
                    dispatch({ type: CONSTANT.MOBILE_VERIFICATION, payload: false })

                    dispatch(unsetLoader());
                }
            })
            .catch((error) => {
                dispatch(unsetLoader());
                // dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}

export function resetOtpVerification() {
    return (
        {
            type: CONSTANT.RESET_OTP
        }
    )
}
