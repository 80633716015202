import React, { Component } from "react";
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";

export default class Loader extends Component {
  render() {
    const { loader } = this.props;
    return (
  
      <Dialog
        open={loader.open}
        aria-labelledby="loading-dialog"
      >
        <DialogTitle id="loading-dialog">
          <CircularProgress />
        </DialogTitle>
      </Dialog>
    );
  }
}
