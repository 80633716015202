import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunk from "redux-thunk";
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import login from './pages/Admin/Reducer';
import loader from './loader/reducer';
import snackbar from './snackbar/snackbar_reducer';
const persistConfig = {
  key: 'root',
  storage,
}

const createStoreWithMiddleware = applyMiddleware(thunk)(createStore);

const reducer = combineReducers({

  login,
  loader,
  snackbar

});

const persistedReducer = persistReducer(persistConfig, reducer)
const configureStore = (initialState) => createStoreWithMiddleware(persistedReducer, initialState);
const store = configureStore();
let persistor = persistStore(store)
const exportedObject = { store, persistor };
export default exportedObject;

