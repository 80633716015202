export const TOKEN = "TOKEN";
export const ORG = "ORG";
export const OWN_ORG = "OWN_ORG";
export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";
export const USER_DETAILS = "USER_DETAILS";
export const PROFILE_VIEW = "PROFILE_VIEW";
export const USER_DATA = "USER_DATA";
export const DEVICE_DATA = "DEVICE_DATA";
export const ORG_DATA = "ORG_DATA";
export const EMAIL_OTP = "EMAIL_OTP";
export const EMAIL_VERIFICATION = "EMAIL_VERIFICATION";
export const MOBILE_OTP = "MOBILE_OTP";
export const MOBILE_VERIFICATION = "MOBILE_VERIFICATION";
export const RESET_OTP = "RESET_OTP";
export const OTP = "OTP";