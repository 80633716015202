import React, { useEffect, useRef, useState } from 'react';
import {
  Grid,
  AppBar,
  Toolbar,
  IconButton,
  Avatar,
  useScrollTrigger,
  Box,
  Typography,
  Button,
  Divider,
  Tooltip,
} from '@mui/material';
import { Home3, Information, Devices, Menu, Element3, MessageQuestion, CloseCircle } from 'iconsax-react';
import Sidebar from './Sidebar';
import Home from './Home';
import Basic from '../../Containers/BasicCont';
import Device from './Devices';
import Organisation from './Organisation';
import LogoDark from '../image/logoDark.png';
import CRM from '../image/CRM.png'
import Newletter from '../image/Newletter.png'

const productImg = [
  {
    name: "CRM",
    imageUrl: CRM
  },
  {
    name: "Newsletter",
    imageUrl: Newletter
  },
];


const smoothScrollTo = (targetId) => {
  const target = document.getElementById(targetId);
  if (!target) return;

  const targetPosition = target.getBoundingClientRect().top + window.pageYOffset;
  const startPosition = window.pageYOffset;
  const distance = targetPosition - startPosition;
  const duration = 500; // Duration in ms
  let startTime = null;

  const animation = (currentTime) => {
    if (!startTime) startTime = currentTime;
    const elapsedTime = currentTime - startTime;
    const progress = Math.min(elapsedTime / duration, 1);
    window.scrollTo(0, startPosition + distance * progress);

    if (elapsedTime < duration) {
      requestAnimationFrame(animation);
    }
  };

  requestAnimationFrame(animation);
};

const Profile = (data) => {
  const props = data

  const [open, setOpen] = useState(false);
  const [product, setProduct] = useState(false);
  const gridRef = useRef(null);
  const [selectedTab, setSelectedTab] = useState('');

  useEffect(() => {
    props.getUserData(props.login.token)
    props.getDeviceData(props.login.token)
    props.getOrgdata(props.login.token)
  }, []);

  const user_data = props.login.user_data;
  const device_data = props.login.device_data;
  const org_data = props.login.org_data;

  const sidebarItems = [
    { text: 'Home', icon: <Home3 size="20" />, id: 'Home' },
    { text: 'Personal info', icon: <Information size='20' />, id: 'Personal info' },
    { text: 'Devices', icon: <Devices size='20' />, id: 'Devices' },
    { text: 'Organisation', icon: <Element3 size='20' />, id: 'Organisation' },
  ];

  const handleScroll = (id) => {
    smoothScrollTo(id);
    setSelectedTab(id);
  };

  const trigger = useScrollTrigger({
    threshold: 100,
  });

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleProductOpen = () => setProduct(true);
  const handleProductClose = () => setProduct(false);

  const handleClickOutside = (event) => {
    if (gridRef.current && !gridRef.current.contains(event.target)) {
      handleClose();
      handleProductClose();
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  return (
    <>
      {/* AppBar for larger screens */}
      <AppBar
        position="sticky"
        elevation={0}
        sx={{
          display: { xs: 'none', sm: 'block' },
          backgroundColor: '#eab676',
          transition: 'transform 0.3s ease',
          transform: trigger ? 'translateY(-100%)' : 'translateY(0)',
        }}
      >
        <Toolbar style={{ justifyContent: 'space-between' }}>
          <Grid container alignItems="center" spacing={1} style={{ flexGrow: 1 }}>
            <Grid item xs>
              <Box display="flex" alignItems="center">
                <img src={LogoDark} width={'170px'} height={'40px'} alt="Logo" />
              </Box>
            </Grid>
            <Grid item>
              <Box display="flex" alignItems="center" spacing={1}>
                <IconButton>
                  <MessageQuestion size='20' color='#000' />
                </IconButton>
                <IconButton onClick={handleProductOpen}>
                  <Menu size='20' variant='Bold' color='#000' />
                </IconButton>
              </Box>
            </Grid>
            <Grid item>
              <IconButton onClick={handleOpen}>
                <Avatar sx={{ width: 30, height: 30 }} src={user_data?.profile_pic} alt="User Logo" />
              </IconButton>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>

      {!trigger && (product &&
        <>
          <Box
            ref={gridRef}
            sx={{
              position: 'fixed',
              top: 75,
              right: 16,
              backgroundColor: '#eab676',
              boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
              borderRadius: '8px',
              padding: 1,
              zIndex: 1200,
              display: { xs: 'none', sm: 'block' }, // Hide on small screens
            }}
          >
            <Box display="flex" flexDirection="column" alignItems="center">
              {productImg.map((item) => (
                <Tooltip title={item.name} key={item.name}>
                  <IconButton>
                    <Avatar sx={{ width: 30, height: 30 }} src={item.imageUrl} alt={item.name} />
                  </IconButton>
                </Tooltip>
              ))}
            </Box>
          </Box>
        </>
      )}

      {/* Fixed box in top-left corner */}
      <Box
        sx={{
          position: 'fixed',
          top: 15,
          left: 15,
          backgroundColor: '#eab676',
          boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
          borderRadius: '8px',
          padding: 0.5,
          zIndex: 1200,
          display: { xs: 'block', sm: 'none' },
        }}
      >
        <Box display="flex" alignItems="center">
          <IconButton>
            <MessageQuestion size='20' color='#000' />
          </IconButton>
          {/* <IconButton onClick={handleProductOpen}>
            <Menu size='20' variant='Bold' color='#000' />
          </IconButton> */}
          <IconButton onClick={handleOpen}>
            <Avatar sx={{ width: 30, height: 30 }} src={user_data?.profile_pic} alt="User Logo" />
          </IconButton>
          {productImg.map((item) => (
            <Tooltip title={item.name} key={item.name}>
              <IconButton>
                <Avatar sx={{ width: 30, height: 30 }} src={item.imageUrl} alt={item.name} />
              </IconButton>
            </Tooltip>
          ))}
        </Box>
      </Box>

      {/* Fixed box in top-right corner */}
      <Box
        sx={{
          position: 'fixed',
          top: 16,
          right: 16,
          backgroundColor: '#eab676',
          boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
          borderRadius: '8px',
          padding: 1,
          zIndex: 1200,
          transition: 'opacity 0.3s ease, transform 0.3s ease',
          opacity: trigger ? 1 : 0,
          transform: trigger ? 'translateY(0)' : 'translateY(-100%)',
          display: { xs: 'none', sm: 'block' }, // Hide on small screens
        }}
      >
        <Box display="flex" flexDirection="column" alignItems="center">
          <IconButton>
            <MessageQuestion size='20' color='#000' />
          </IconButton>
          {/* <IconButton onClick={handleProductOpen}>
            <Menu size='20' variant='Bold' color='#000' />
          </IconButton> */}
          <IconButton onClick={handleOpen}>
            <Avatar sx={{ width: 30, height: 30 }} src={user_data?.profile_pic} alt="User Logo" />
          </IconButton>
          <Divider sx={{ width: '100%', backgroundColor: '#d17407', margin: 1 }} />
          {productImg.map((item) => (
            <Tooltip title={item.name} key={item.name}>
              <IconButton>
                <Avatar sx={{ width: 30, height: 30 }} src={item.imageUrl} alt={item.name} />
              </IconButton>
            </Tooltip>
          ))}
        </Box>
      </Box>

      <Grid container>
        <Grid item xs={12} sm={3} sx={{ display: { xs: 'none', sm: 'none', md: 'block' }, position: 'fixed', top: 0, left: 0, width: '18%', borderRight: '1px solid #ebecf0', padding: 2, minHeight: '100vh', overflowY: 'auto', scrollbarColor: '#fff' }}>
          <center><img src={LogoDark} width={'150px'} alt="Logo" style={{ marginBottom: '20px' }} /></center>
          <Sidebar items={sidebarItems} selectedTab={selectedTab} setSelectedTab={handleScroll} />
        </Grid>
        <Grid item ml={{ xs: 0, sm: 0, md: '18%' }} style={{ padding: '0 20px' }}>
          <div id="Home" style={{ marginBottom: '20px' }}>
            <Home user_data={user_data} scrollToSection={handleScroll} />
          </div>
          <div id="Personal info" style={{ marginBottom: '20px' }}>
            <Basic user_data={user_data} />
          </div>
          <div id="Devices" style={{ marginBottom: '20px' }}>
            <Device device_data={device_data} />
          </div>
          <div id="Organisation" style={{ marginBottom: '20px' }}>
            <Organisation org_data={org_data} />
          </div>
        </Grid>
      </Grid>

      <Grid container
        position="fixed"
        bottom={0}
        width="100%"
        sx={{ display: { xs: 'flex', sm: 'flex', md: 'none' }, justifyContent: 'space-around', backgroundColor: '#ffffff', boxShadow: '0px -2px 4px rgba(0, 0, 0, 0.1)' }}>
        {sidebarItems.map((item) => (
          <Grid key={item.id} item>
            <IconButton style={{ flexDirection: 'column', color: selectedTab === item.id ? '#d17407' : 'inherit' }} onClick={() => handleScroll(item.id)}>
              {item.icon}
              <Typography fontSize={'13px'} style={{ color: selectedTab === item.id ? '#d17407' : 'inherit' }}>{item.text}</Typography>
            </IconButton>
          </Grid>
        ))}
      </Grid>

      {open && (
        <Grid
          ref={gridRef}
          style={{
            position: 'fixed',
            top: 90,
            right: 90,
            boxShadow: '2px 2px 10px rgba(0, 0, 0, 0.2)',
            borderRadius: 30,
            padding: 1,
            zIndex: 1200,
            backgroundColor: '#eab676',
            width: '400px'
          }}
        >
          <Grid mt={1}>
            <center>
              <Typography className='text' variant='subtitle2' color={'GrayText'}>{user_data.email_id}</Typography>
            </center>
            <Grid container justifyContent="flex-end" mt={-3} pr={1}>
              <IconButton onClick={handleClose}>
                <CloseCircle size='20' color='#000' />
              </IconButton>
            </Grid>
          </Grid>
          <Grid sx={{ padding: 3 }}>
            <Grid container direction="column" alignItems="center" spacing={2}>
              <Grid item mt={-2}>
                <Avatar src={user_data.profile_pic} sx={{ width: 100, height: 100 }} />
              </Grid>
              <Grid item>
                <Typography className='text' variant="h5">Hi, {user_data.name}!</Typography>
              </Grid>
              <Grid item>
                <Button className='text' variant="outlined"
                  sx={{
                    fontSize: '12px',
                    borderRadius: 3,
                    textTransform: 'none',
                    color: '#000',
                    border: '1px dashed #000',
                    '&:hover': {
                      border: '1px solid #000'
                    },
                  }}>
                  Manage your Technoboot Account
                </Button>
              </Grid>
            </Grid>
            <Grid container justifyContent={'center'} pt={2} spacing={.2}>
              <Grid item xs={6}>
                <Button fullWidth
                  // startIcon={<AddIcon />}
                  variant="outlined"
                  className='text'
                  sx={{
                    textTransform: 'none',
                    borderRadius: '16px 0px 0px 16px',
                    padding: '16px',
                    color: '#1a73e8',
                    backgroundColor: '#fff',
                    border: 'none',
                    fontSize: '12.5px',
                    '&:hover': {
                      backgroundColor: '#e8f0fe', // Change background on hover
                      color: '#000',
                      border: '1px solid #000'
                    },
                  }}
                >
                  Switch Organisation
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button fullWidth
                  // startIcon={<ExitToAppIcon />}
                  variant="outlined"
                  className='text'
                  sx={{
                    textTransform: 'none',
                    borderRadius: '0px 16px 16px 0px',
                    padding: '16px',
                    color: '#1a73e8',
                    backgroundColor: '#fff',
                    border: 'none',
                    fontSize: '12.5px',
                    '&:hover': {
                      color: '#000 ',
                      border: '1px solid #000'
                    },
                  }}
                  onClick={() => { data.logOutByToken(props.login.token) }}
                >
                  Sign out
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid container justifyContent="center" p={.5}>
            <Typography variant="caption" color="textSecondary">
              Privacy Policy • Terms of Service
            </Typography>
          </Grid>
        </Grid>
      )}
      

    </>
  );
};

export default Profile;
