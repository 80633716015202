import React, { Component } from 'react'
// import { Grid } from 'rsuite'
import { Grid, Card, Typography, CardContent, Button, TextField, IconButton } from '@mui/material';
import CallIcon from '@mui/icons-material/Call';
import sticker from "../Components/image/sticker.png"
import Style from "../Components/Style.css"
import gamil from "../Components/image/gmail_732200.png"
import logo from "../Components/image/logoDark.png"
import loginimg from "../Components/image/login1.avif"
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { useParams, useLocation } from "react-router-dom";
// import { GoogleLogin } from 'react-google-login';
import LoaderCon from '../../../loader/containers/loader_cont'
const clientId = '1051912188369-fu5lecssgca1q9vf693c5bvli13eruap.apps.googleusercontent.com';
function withParams(Component) {
  return props => <Component {...props} params={useParams()} location={useLocation()} />;
}

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      phoneNo: "",
      name: "",
      mobile: "",
      showSecondCard: false,
      showPassword: false,
      countdown: 30,
      isLoggedIn: false,
      promptPending: false,
      login_id: this.props.params.object_id,
      errors: {},
    };
    this.handleCredentialResponse = this.handleCredentialResponse.bind(this);
    this.handleGoogleLogin = this.handleGoogleLogin.bind(this);
  }

  toggleSecondCard = () => {
    this.setState(prevState => ({
      showSecondCard: !prevState.showSecondCard
    }));
  }

  handleClickShowPassword = () => {
    this.setState((state) => ({
      showPassword: !state.showPassword,
    }));
  };

  handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  handleSendOTP = (email) => {
    // Logic to send OTP
    // Set otpSent to true
    this.props.login_by_email(email, this.props.params.object_id)
    this.startTimer();
  }
  handleSendOTP1 = (phoneNo) => {
    // Logic to send OTP
    // Set otpSent to true
    this.props.login_by_mobile(phoneNo, this.props.params.object_id)
    this.startTimer();
  }
  componentDidMount() {
    // Initialize the Google Identity Services client
    if (this.props.location) {
      const { search } = this.props.location;
      const params = new URLSearchParams(search);
      const encodedData = params.get('data');
      if (encodedData) {
        const { token, object_id } = JSON.parse(decodeURIComponent(encodedData));
        console.log(token, object_id)
        debugger
        this.props.checkObjectId(token, object_id)
        localStorage.setItem('token', token)
      }
    }
    this.loadGoogleScript();
    this.props.setOtp(false)
    // this.props.create_login()
    // 
  }
  loadGoogleScript() {
    const script = document.createElement('script');
    script.src = 'https://accounts.google.com/gsi/client';
    script.async = true;
    script.defer = true;
    script.onload = this.initializeGoogleLogin.bind(this);
    document.body.appendChild(script);
  }

  handleSignInButtonClick = (event) => {
    const additionalId = this.props.params.object_id;
    window.location.href = `https://ssoapi.technoboot.co/api/v1/auth/google?additionalId=${additionalId}`;
  }
  async handleGoogleLogin() {
    try {
      
      const customData = {
        login_id: this.state.login_id
      };

      const params = new URLSearchParams({
        state: JSON.stringify(customData),
      });

      const response = await fetch(`https://ssoapi.technoboot.co/api/v1/auth/google?${params.toString()}`);
      const { url } = await response.json();
      window.location.href = url;
    } catch (error) {
      console.error('Google Login Error:', error);
    }
  }

  async handleLogout() {
    try {
      await fetch('https://ssoapi.technoboot.co/api/v1/auth/logout', {
        method: 'POST',
        credentials: 'include', // Include cookies in the request
      });

      // const response = await fetch('https://ssoapi.technoboot.co/api/v1/auth/logout/google');
      // const { url } = await response.json();

      // // this.props.setUser(null); // Clear the user state
      // window.location.href = url;   // Redirect to Google logout URL
    } catch (error) {
      console.error('Logout Error:', error);
    }
  }
  loadGoogleScript() {
    
    const script = document.createElement('script');
    script.src = 'https://accounts.google.com/gsi/client';
    script.async = true;
    script.defer = true;
    script.onload = this.initializeGoogleLogin.bind(this);
    script.onerror = () => console.error('Failed to load Google script.');
    document.body.appendChild(script);
  }
  initializeGoogleLogin() {
    
    if (window.google && window.google.accounts) {
      window.google.accounts.id.initialize({
        client_id: clientId,
        callback: this.handleCredentialResponse,
        scope: ['https://www.googleapis.com/auth/userinfo.profile', 'https://www.googleapis.com/auth/userinfo.email'],

      });
      

      window.google.accounts.id.renderButton(
        document.getElementById('buttonDiv'),
        { theme: 'outline', size: 'large' } // customization attributes
      );
      

      this.displayPrompt();
    } else {
      console.error('Google accounts object is not available.');
    }
  }
  displayPrompt() {
    
    if (!this.state.promptPending) {
      this.setState({ promptPending: true });
      window.google.accounts.id.prompt(notification => {
        this.setState({ promptPending: false });

        if (notification.isNotDisplayed() || notification.isSkippedMoment()) {
          
        }
      });
    }
  }
  handleCredentialResponse(response) {
    
    fetch('https://ssoapi.technoboot.co/api/v1/auth/google/fedm/callback', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ token: response.credential, state: this.props.params.object_id }),

    })
      .then(response => response.json())
      .then(data => {
        
        if (data.statusCode === 200 || data.statusCode === 200) {
          localStorage.setItem('token', data.data.token)
          this.props.checkToken(data.data.token)
        }
        else {

        }
        this.setState({ isLoggedIn: true });
      });
  }
  startTimer = () => {
    // Decrease countdown every second
    this.timer = setInterval(() => {
      this.setState(prevState => ({
        countdown: prevState.countdown - 1
      }), () => {
        // If countdown reaches 0, stop the timer
        if (this.state.countdown === 0) {
          clearInterval(this.timer);
        }
      });
    }, 1000);
  }
  resetTimer = (email) => {
    this.props.login_by_email(email, this.props.params.object_id)
    clearInterval(this.timer); // Stop the timer
    this.setState({ countdown: 30 * 2, otpSent: false, });
    this.startTimer()
    // Reset countdown and otpSent
  }
  resetTimer2 = (phoneNo) => {
    this.props.login_by_mobile(phoneNo, this.props.params.object_id)
    clearInterval(this.timer); // Stop the timer
    this.setState({ countdown: 30 * 2, otpSent: false, });
    this.startTimer()
    // Reset countdown and otpSent
  }
  handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();

      if (!this.props.login.otp) {
        this.handleSendOTP(this.state.email_id, this.props.params.object_id);
        return;
      }

      const { name } = this.props.login.user_details;
      if (name !== undefined && name !== null && name !== "") {
        this.props.verify_otp_email(this.state.email_id, this.state.otp, this.props.params.object_id);
        return;
      }

      if (this.validateFields()) {
        this.props.verify_otp_email_new_user(
          this.state.email_id,
          this.state.otp,
          this.props.params.object_id,
          this.state.mobile,
          this.state.name
        );
      }
    }
  };


  validateFields = () => {
    const errors = {};
    if (!this.state.name) errors.name = "Name is required";
    if (!this.state.email_id) {
      errors.email_id = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(this.state.email_id)) {
      errors.email_id = "Email is invalid";
    }
    if (!this.state.mobile) {
      errors.mobile = "Mobile number is required";
    } else if (!/^\d{10}$/.test(this.state.mobile)) {
      errors.mobile = "Mobile number must be 10 digits";
    }
    // if (!this.state.employeeID) errors.employeeID = "Employee Id is required";
    // if (!this.state.role) errors.role = "Role is required";
    this.setState({ errors });
    return Object.keys(errors).length === 0;
  };
  handleKeyPress1 = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      if (!this.props.login.otp) {
        this.handleSendOTP1(this.state.phoneNo, this.props.params.object_id);
      } else {

        this.props.verify_otp_mobille(this.state.phoneNo, this.state.otp, this.props.params.object_id)

      }
    }
  };

  render() {
    const { showPassword, countdown, loading, errors } = this.state

    return (

      <Grid container justifyContent="center" alignItems="center" style={{ height: '100vh', background: 'linear-gradient(to right top, #c6ece5, #c7eee5, #c9f0e4, #cbf2e3, #cdf4e2, #cbf5e0, #caf5de, #c9f6dc, #c3f6d8, #bef6d5, #b8f6d1, #b3f6cd)', overflow: 'hidden' }}>
        {/* <button onClick={this.handleLogout}>Logout</button> */}
        <Grid container justify="center" style={{ width: "60%", marginBottom: "20px" }}>
          <Grid item xs={12} className="parentOne" style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "60%" }}>
            <Card
              style={{ display: "flex", width: "50%", borderRadius: "5px", height: "100%", justifyContent: "center", padding: '20px', alignItems: "center", transition: 'transform 0.3s', '&:hover': { transform: 'scale(1.05)' }, width: "100%", height: "100%", marginTop: "-10px" }}
            >
              {!this.state.showSecondCard && (
                <>
                  <Grid item xs={6} className="frame" style={{ borderRadius: "5px", width: "100%", marginBottom: "10px", }}>
                    <img src={loginimg} style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                  </Grid>
                  <Grid item xs={6} className="Secondframe" style={{ width: "100%" }}>
                    <CardContent>
                      <Grid item xs={12} style={{ display: "flex", justifyContent: "center", alignItems: "center", marginBottom: "20px" }}>
                        <img src={logo} style={{ width: '50%', height: '50%', objectFit: 'cover' }} />
                      </Grid>
                      <Typography variant="h5" align="center" gutterBottom style={{ marginBottom: "10px", marginBottom: "20px", fontWeight: "400", color: "#66666e" }}>
                        Login to continue
                      </Typography>

                      <form>
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <TextField
                              fullWidth
                              disabled={this.props.login.otp}
                              label="Enter your Email"
                              variant="outlined"
                              value={this.state.email_id}
                              autoComplete='off'
                              onChange={(event) => this.setState({ email_id: event.target.value })}
                              onKeyPress={this.handleKeyPress}
                              error={!!errors.email_id}
                              helperText={errors.email_id}
                            />
                            {this.props.login.otp ?

                              <Grid item xs={12}>
                                <Grid container>
                                  {(this.props.login.user_details?.name === undefined || this.props.login.user_details?.name === null || this.props.login.user_details?.name === "") &&

                                    <>
                                      <Grid item xs={12}>
                                        <TextField
                                          fullWidth
                                          style={{ marginTop: '10px' }}
                                          label='Enter Your Name'
                                          onChange={(e) => this.setState({ name: e.target.value })}
                                          variant="outlined"
                                          type='text'
                                          error={!!errors.name}
                                          helperText={errors.name}
                                        />
                                      </Grid>
                                      <Grid item xs={12}>
                                        <TextField
                                          fullWidth
                                          style={{ marginTop: '10px' }}
                                          label='Enter Your Mobile Number'
                                          onChange={(e) => this.setState({ mobile: e.target.value })}
                                          variant="outlined"
                                          type='text'
                                          error={!!errors.mobile}
                                          helperText={errors.mobile}
                                        />
                                      </Grid>
                                    </>
                                  }
                                  <Grid item xs={12}>
                                    <TextField
                                      fullWidth
                                      style={{ marginTop: '10px' }}
                                      placeholder='Enter OTP'
                                      onChange={(e) => this.setState({ otp: e.target.value })}
                                      variant="outlined"
                                      type={showPassword ? 'text' : 'password'}
                                      autoComplete='off'
                                      onKeyPress={this.handleKeyPress}
                                      InputProps={{
                                        endAdornment: (
                                          <InputAdornment position="end">
                                            <IconButton
                                              aria-label="toggle password visibility"
                                              onClick={this.handleClickShowPassword}
                                              onMouseDown={this.handleMouseDownPassword}
                                              edge="end"
                                            >
                                              {showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                          </InputAdornment>
                                        ),
                                      }}
                                    />
                                  </Grid>
                                  <Grid item xs={12}>
                                    <Button variant="contained" fullWidth style={{ backgroundColor: "#344e41", marginTop: '15px' }}
                                      onClick={() => {
                                        const { name } = this.props.login.user_details;
                                        if (name !== undefined && name !== null && name !== "") {
                                          
                                          this.props.verify_otp_email(this.state.email_id, this.state.otp, this.props.params.object_id);
                                        } else {
                                          if (this.validateFields()) {
                                            
                                            this.props.verify_otp_email_new_user(
                                              this.state.email_id,
                                              this.state.otp,
                                              this.props.params.object_id,
                                              this.state.mobile,
                                              this.state.name
                                            );
                                          }
                                        }
                                      }}
                                    >
                                      <Typography >
                                        VERIFY OTP
                                      </Typography>
                                    </Button>
                                  </Grid>
                                  <Grid item xs={12}>
                                    <Grid container>
                                      <Grid item xs={5}>
                                        <Button
                                          disabled={countdown !== 0}
                                          variant="text" // Use text variant
                                          fullWidth
                                          style={{
                                            backgroundColor: "transparent", // Set background color to transparent
                                            border: "none", // Remove border
                                            color: "blue", // Inherit color
                                          }}
                                          onClick={() => { this.resetTimer(this.state.email) }}
                                        >
                                          <Typography>
                                            RESEND OTP
                                          </Typography>
                                        </Button>

                                      </Grid>
                                      <Grid item xs={2}>

                                      </Grid>
                                      <Grid item xs={5}>
                                        <Typography>
                                          Resend OTP in {countdown} seconds
                                        </Typography>
                                      </Grid>
                                    </Grid>
                                  </Grid>

                                </Grid>
                              </Grid>
                              :

                              <Grid item xs={12}>
                                <Button variant="contained" fullWidth style={{ backgroundColor: "#344e41", marginTop: '15px' }}
                                  onClick={() => {
                                    
                                    this.handleSendOTP(this.state.email_id, this.props.params.object_id)
                                  }}
                                >
                                  <Typography >
                                    SEND OTP
                                  </Typography>
                                </Button>
                              </Grid>
                            }
                          </Grid>



                          <Grid item xs={12} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>

                            <Typography style={{ color: "#e29578", fontWeight: "500", marginBottom: "20px" }}>
                              or continue with
                            </Typography>

                          </Grid>


                          {/*<Grid item xs={12}>
                            <Button variant="outlined" startIcon={<img width="30" height="30" src="https://img.icons8.com/fluency/48/phone--v1.png" alt="phone--v1" />} fullWidth style={{ borderColor: '#6c584c', color: '#0077b6' }} onClick={this.toggleSecondCard}>
                              <Typography style={{ color: "#6c584c" }}>
                                PHONE
                              </Typography>
                            </Button>
                          </Grid>*/}
                          <Grid item xs={12} >
                            {/* <Grid container>
                              <Grid xs={3} />
                              <Grid xs={8}><div id="buttonDiv"></div></Grid>
                              <Grid xs={1} />
                            </Grid> */}

                            <Button variant="outlined"
                              onClick={this.handleGoogleLogin}
                              startIcon={<img width="30" height="30" src="https://img.icons8.com/3d-fluency/94/google-logo.png" alt="google-logo" />} fullWidth style={{ borderColor: '#6c584c', color: '#0077b6' }}
                            >
                              <Typography style={{ color: "#6c584c" }}>
                                Google
                              </Typography>
                            </Button>
                          </Grid>
                        </Grid>
                      </form>
                    </CardContent>
                  </Grid>
                </>
              )}

              {this.state.showSecondCard && (

                <>
                  <Grid item xs={6} className="frame" style={{ borderRadius: "5px", width: "100%", marginBottom: "10px" }}>
                    <img src={loginimg} style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                  </Grid>
                  <Grid item xs={6} className="Secondframe" style={{ width: "100%" }}>
                    <CardContent>
                      <Grid item xs={12} className="itemFrame" style={{ display: "flex", justifyContent: "center", alignItems: "center", marginBottom: "20px" }}>
                        <img src={logo} style={{ width: '50%', height: '50%', objectFit: 'cover' }} />
                      </Grid>
                      <Typography variant="h5" align="center" gutterBottom style={{ marginBottom: "10px", marginBottom: "20px", fontWeight: "400", color: "#66666e" }}>
                        Login to continue
                      </Typography>
                      <form>
                        <Grid container className="itemFrame" spacing={2}>
                          <Grid item xs={12} >
                            <TextField
                              disabled={this.props.login.otp}
                              fullWidth
                              label="Enter your Phone Number"
                              variant="outlined"
                              value={this.state.phoneNo}
                              type={showPassword ? 'text' : 'password'}
                              autoComplete='off'
                              onChange={(event) => this.setState({ phoneNo: event.target.value })}
                              onKeyPress={this.handleKeyPress1}
                            />
                            {this.props.login.otp ?

                              <Grid item xs={12}>
                                <Grid container>
                                  <Grid item xs={12}>
                                    <TextField
                                      fullWidth
                                      style={{ marginTop: '10px' }}
                                      placeholder='Enter OTP'
                                      onChange={(e) => this.setState({ otp: e.target.value })}
                                      variant="outlined"
                                      type={showPassword ? 'text' : 'password'}
                                      autoComplete='off'
                                      onKeyPress={this.handleKeyPress1}
                                      InputProps={{
                                        endAdornment: (
                                          <InputAdornment position="end">
                                            <IconButton
                                              aria-label="toggle password visibility"
                                              onClick={this.handleClickShowPassword}
                                              onMouseDown={this.handleMouseDownPassword}
                                              edge="end"
                                            >
                                              {showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                          </InputAdornment>
                                        ),
                                      }}
                                    />
                                  </Grid>
                                  <Grid item xs={12}>
                                    <Button variant="contained" fullWidth style={{ backgroundColor: "#344e41", marginTop: '15px' }}
                                      onClick={() => {
                                        this.props.verify_otp_mobille(this.state.phoneNo, this.state.otp, this.props.params.object_id)
                                      }}
                                    >
                                      <Typography >
                                        VERIFY OTP
                                      </Typography>
                                    </Button>
                                  </Grid>
                                  <Grid item xs={12}>
                                    <Grid container>
                                      <Grid item xs={5}>
                                        <Button
                                          disabled={countdown !== 0}
                                          variant="text" // Use text variant
                                          fullWidth
                                          style={{
                                            backgroundColor: "transparent", // Set background color to transparent
                                            border: "none", // Remove border
                                            color: "blue", // Inherit color
                                          }}
                                          onClick={() => { this.resetTimer2(this.state.phoneNo) }}
                                        >
                                          <Typography>
                                            RESEND OTP
                                          </Typography>
                                        </Button>

                                      </Grid>
                                      <Grid item xs={2}>

                                      </Grid>
                                      <Grid item xs={5}>
                                        <Typography>
                                          Resend OTP in {countdown} seconds
                                        </Typography>
                                      </Grid>
                                    </Grid>
                                  </Grid>

                                </Grid>
                              </Grid>
                              :
                              <Grid item xs={12}>
                                <Button variant="contained" fullWidth style={{ backgroundColor: "#344e41", marginTop: '15px' }}
                                  onClick={() => {
                                    
                                    this.handleSendOTP1(this.state.phoneNo, this.props.params.object_id)
                                  }}
                                >
                                  <Typography >
                                    SEND OTP
                                  </Typography>
                                </Button>
                              </Grid>
                            }
                          </Grid>


                          <Grid item xs={12} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>

                            <Typography style={{ color: "#e29578", fontWeight: "500", marginBottom: "20px" }}>
                              or continue with
                            </Typography>

                          </Grid>


                          <Grid item xs={12}>
                            <Button variant="outlined" startIcon={<img width="30" height="30" src="https://img.icons8.com/3d-fluency/94/gmail.png" alt="gmail" />} fullWidth style={{ borderColor: '#6c584c', color: '#0077b6' }} onClick={this.toggleSecondCard}>
                              <Typography style={{ color: "#6c584c" }}>
                                Email
                              </Typography>
                            </Button>
                          </Grid>
                          <Grid item xs={12}>
                            {/* <Button
                              onClick={this.handleGoogleLogin}
                              variant="outlined"
                              startIcon={<img width="30" height="30" src="https://img.icons8.com/3d-fluency/94/google-logo.png" alt="google-logo" />}
                              fullWidth
                              style={{ borderColor: '#6c584c', color: '#0077b6' }}
                            >
                              <Typography style={{ color: "#6c584c" }}>
                                Google
                              </Typography>
                            </Button> */}
                          </Grid>
                        </Grid>
                      </form>
                    </CardContent>
                  </Grid>
                </>

              )}

            </Card>


          </Grid>
        </Grid>
        <LoaderCon />
      </Grid>


    )
  }
}
export default withParams(Login)
